<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 433.904 279.476">
  <g id="undraw_donut_love_kau1" transform="translate(0 0)">
    <path id="Pfad_727" data-name="Pfad 727" d="M187.3,597.655l-3.063-12.083a156.9,156.9,0,0,0-16.646-5.972l-.387,5.615-1.568-6.187c-7.015-2-11.787-2.885-11.787-2.885s6.446,24.513,19.967,43.254l15.755,2.767-12.239,1.765a63.467,63.467,0,0,0,5.475,5.8c19.669,18.257,41.576,26.633,48.931,18.709s-2.627-29.148-22.3-47.4c-6.1-5.66-13.757-10.2-21.425-13.776Z" transform="translate(-153.846 -372.866)" fill="#dce3eb"/>
    <path id="Pfad_728" data-name="Pfad 728" d="M258.069,555.732l3.623-11.927a156.9,156.9,0,0,0-11.163-13.717L247.3,534.7l1.855-6.107c-4.97-5.341-8.6-8.563-8.6-8.563s-7.153,24.316-5.266,47.348l12.056,10.513-11.39-4.816a63.476,63.476,0,0,0,1.688,7.8c7.4,25.8,21.824,44.29,32.217,41.308s12.818-26.31,5.418-52.105c-2.294-8-6.5-15.844-11.22-22.868Z" transform="translate(-195.481 -344.065)" fill="#dce3eb"/>
    <path id="Pfad_729" data-name="Pfad 729" d="M947.274,619.792l6.868-4.317a102.1,102.1,0,0,0,.364-11.5l-3.555.885,3.516-2.21c-.165-4.745-.574-7.877-.574-7.877s-13.887,8.9-22.784,21.028l1.442,10.31-3.544-7.225a41.309,41.309,0,0,0-2.5,4.552c-7.365,15.836-8.163,31.079-1.783,34.046s17.522-7.465,24.887-23.3a54.388,54.388,0,0,0,4.239-16.026Z" transform="translate(-547.276 -382.425)" fill="#dce3eb"/>
    <path id="Pfad_730" data-name="Pfad 730" d="M962.375,658.861l8.111-.146a102.1,102.1,0,0,0,6.257-9.66l-3.5-1.079,4.153-.075c2.312-4.147,3.58-7.04,3.58-7.04s-16.49.442-30.373,6.224l-4.094,9.571.7-8.017a41.29,41.29,0,0,0-4.49,2.607c-14.49,9.749-23.053,22.385-19.125,28.223s18.859,2.667,33.349-7.083a54.392,54.392,0,0,0,11.912-11.528Z" transform="translate(-548.416 -406.082)" fill="#dce3eb"/>
    <rect id="Rechteck_212" data-name="Rechteck 212" width="432.25" height="1.091" transform="translate(0.988 278.077)" fill="#3f3d56"/>
    <rect id="Rechteck_213" data-name="Rechteck 213" width="1.252" height="6.763" transform="matrix(0.894, 0.448, -0.448, 0.894, 122.581, 29.534)" fill="#fff"/>
    <rect id="Rechteck_214" data-name="Rechteck 214" width="0.373" height="2.016" transform="translate(116.88 181.41) rotate(-6.648)" fill="#3f3d56"/>
    <rect id="Rechteck_215" data-name="Rechteck 215" width="0.373" height="2.016" transform="translate(119.346 198.685) rotate(71.644)" fill="#fff"/>
    <rect id="Rechteck_216" data-name="Rechteck 216" width="0.373" height="2.016" transform="translate(117.358 188.68) rotate(-3.441)" fill="#fff"/>
    <rect id="Rechteck_217" data-name="Rechteck 217" width="0.373" height="2.016" transform="translate(115.616 177.645) rotate(-3.441)" fill="#fff"/>
    <rect id="Rechteck_218" data-name="Rechteck 218" width="0.373" height="2.016" transform="matrix(0.375, 0.927, -0.927, 0.375, 142.478, 185.507)" fill="#ff6584"/>
    <rect id="Rechteck_219" data-name="Rechteck 219" width="0.373" height="2.016" transform="matrix(0.966, 0.259, -0.259, 0.966, 120.219, 185.169)" fill="#ff6584"/>
    <rect id="Rechteck_220" data-name="Rechteck 220" width="1.177" height="6.356" transform="translate(139.449 34.369)" fill="#3f3d56"/>
    <rect id="Rechteck_221" data-name="Rechteck 221" width="1.177" height="6.356" transform="translate(138.743 49.671)" fill="#fff"/>
    <rect id="Rechteck_222" data-name="Rechteck 222" width="0.351" height="1.895" transform="translate(138.78 182.265) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_223" data-name="Rechteck 223" width="0.351" height="1.895" transform="translate(142.303 202.559) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_224" data-name="Rechteck 224" width="0.351" height="1.895" transform="matrix(0.966, 0.259, -0.259, 0.966, 139.402, 194.304)" fill="#ff6584"/>
    <rect id="Rechteck_225" data-name="Rechteck 225" width="0.351" height="1.895" transform="translate(140.368 187.227) rotate(45)" fill="#ff6584"/>
    <path id="Pfad_731" data-name="Pfad 731" d="M374.6,540.31a1.61,1.61,0,0,0-2.276-2.276L320.962,589.4a1.61,1.61,0,1,0,2.276,2.276L374.27,540.64" transform="translate(-239.373 -353.065)" fill="#3f3d56"/>
    <path id="Pfad_732" data-name="Pfad 732" d="M398.892,522.427a24.2,24.2,0,1,0,6.025,24.245A24.2,24.2,0,0,0,398.892,522.427Zm-19.813,26.331a10.015,10.015,0,0,1-4.405-2.571c-.247-.247-.476-.508-.7-.773a10.038,10.038,0,1,1,5.1,3.344Z" transform="translate(-258.415 -341.665)" fill="var(--fuse-primary)"/>
    <path id="Pfad_733" data-name="Pfad 733" d="M318.385,247.789a5.4,5.4,0,0,0-10.8,0V475.372a5.4,5.4,0,1,0,10.8,0V249.357" transform="translate(-232.75 -201.573)" fill="#3f3d56"/>
    <path id="Pfad_734" data-name="Pfad 734" d="M309.886,203.7a81.236,81.236,0,1,0,9.419,37.992A81.236,81.236,0,0,0,309.886,203.7Zm-55.252,67.315A33.668,33.668,0,1,1,271.752,241.7,33.668,33.668,0,0,1,254.634,271.019Z" transform="translate(-155.372 -159.505)" fill="var(--fuse-primary)"/>
    <path id="Pfad_735" data-name="Pfad 735" d="M237.659,400.627a81.213,81.213,0,0,1-81.172-78.681q-.042,1.316-.043,2.644a81.216,81.216,0,1,0,162.431,0q0-1.33-.043-2.649A81.213,81.213,0,0,1,237.659,400.627Z" transform="translate(-155.179 -242.401)" opacity="0.2"/>
    <path id="Pfad_736" data-name="Pfad 736" d="M281.048,244.131a40.524,40.524,0,1,0,40.526,40.526A40.524,40.524,0,0,0,281.048,244.131Zm16.546,69.85a33.668,33.668,0,1,1,17.118-29.323,33.668,33.668,0,0,1-17.118,29.323Z" transform="translate(-198.333 -202.467)" opacity="0.2"/>
    <path id="Pfad_737" data-name="Pfad 737" d="M321.359,201.76v13.51a4.94,4.94,0,0,1-8.508,3.42v1.54a5.869,5.869,0,1,1-11.737,0v-1.283a4.937,4.937,0,0,1-8.239-3.677l-.235-14.36V197.6a2,2,0,0,0-4,0v3.312l-.235,12.006a4.944,4.944,0,0,1-9.887,0l-.605-12.006v-6.137a3.279,3.279,0,0,0-6.558,0v6.137l-.37,12.006a4.94,4.94,0,0,1-8.828,3.051,4.93,4.93,0,0,1-6.742.979v3.284a5.869,5.869,0,1,1-11.737,0V210.091a4.942,4.942,0,0,1-9.449,2.027v8.112a5.869,5.869,0,1,1-11.737,0V200.91h-.033v19.32a5.869,5.869,0,1,1-11.737,0v-8.044a4.941,4.941,0,0,1-9.416-2.1V200.91h-.5v16.5a5.869,5.869,0,1,1-11.737,0v-7.314a4.944,4.944,0,1,1-9.887,0V199.208a81.244,81.244,0,0,1,142.186,2.552Z" transform="translate(-166.844 -158.537)" fill="#fff"/>
    <path id="Pfad_738" data-name="Pfad 738" d="M249.846,285.343a5.868,5.868,0,0,1-5.869-5.869v3.061a5.869,5.869,0,1,0,11.737,0v-3.06a5.868,5.868,0,0,1-5.869,5.869Z" transform="translate(-200.104 -220.606)" opacity="0.2"/>
    <path id="Pfad_739" data-name="Pfad 739" d="M274.027,285.343a5.868,5.868,0,0,1-5.869-5.869v3.061a5.869,5.869,0,1,0,11.737,0v-3.06a5.868,5.868,0,0,1-5.869,5.869Z" transform="translate(-212.515 -220.606)" opacity="0.2"/>
    <path id="Pfad_740" data-name="Pfad 740" d="M234.05,260.74a4.941,4.941,0,0,1-9.416-2.1v3.06a4.941,4.941,0,0,0,9.416,2.1Z" transform="translate(-190.176 -209.916)" opacity="0.2"/>
    <rect id="Rechteck_226" data-name="Rechteck 226" width="0.033" height="3.06" transform="translate(55.611 39.548)" opacity="0.2"/>
    <path id="Pfad_741" data-name="Pfad 741" d="M189.06,258.645a4.944,4.944,0,1,1-9.887,0v3.06a4.944,4.944,0,0,0,9.887,0Z" transform="translate(-166.845 -209.916)" opacity="0.2"/>
    <rect id="Rechteck_227" data-name="Rechteck 227" width="0.504" height="3.06" transform="translate(33.953 39.548)" opacity="0.2"/>
    <path id="Pfad_742" data-name="Pfad 742" d="M205.354,279.539a5.868,5.868,0,0,1-5.869-5.869v3.061a5.869,5.869,0,0,0,11.737,0v-3.06a5.868,5.868,0,0,1-5.869,5.869Z" transform="translate(-177.27 -217.627)" opacity="0.2"/>
    <path id="Pfad_743" data-name="Pfad 743" d="M317.552,285.343a5.868,5.868,0,0,1-5.869-5.869v3.061a5.869,5.869,0,0,0,11.737,0v-3.06a5.868,5.868,0,0,1-5.869,5.869Z" transform="translate(-234.853 -220.606)" opacity="0.2"/>
    <path id="Pfad_744" data-name="Pfad 744" d="M408.055,230.87a2,2,0,1,0-4,0v3.06a2,2,0,1,1,4,0Z" transform="translate(-282.26 -194.634)" opacity="0.2"/>
    <path id="Pfad_745" data-name="Pfad 745" d="M301.72,258.645a4.942,4.942,0,0,1-9.449,2.027v3.06a4.942,4.942,0,0,0,9.449-2.027Z" transform="translate(-224.89 -209.916)" opacity="0.2"/>
    <path id="Pfad_746" data-name="Pfad 746" d="M387.564,256.733a4.943,4.943,0,0,1-4.944-4.944l-.605-12.005v3.06l.605,12.006a4.944,4.944,0,1,0,9.887,0l.235-12.006v-3.06l-.235,12.006a4.943,4.943,0,0,1-4.944,4.943Z" transform="translate(-270.949 -200.236)" opacity="0.2"/>
    <path id="Pfad_747" data-name="Pfad 747" d="M436.051,259.087a4.927,4.927,0,0,1-3.564-1.523v1.54a5.869,5.869,0,1,1-11.737,0v-1.283a4.937,4.937,0,0,1-8.239-3.677l-.235-14.36v3.06l.235,14.36a4.937,4.937,0,0,0,8.239,3.677v1.283a5.869,5.869,0,1,0,11.737,0v-1.54a4.94,4.94,0,0,0,8.508-3.421v-3.06a4.943,4.943,0,0,1-4.944,4.943Z" transform="translate(-286.48 -200.236)" opacity="0.2"/>
    <path id="Pfad_748" data-name="Pfad 748" d="M346.423,256.733a4.932,4.932,0,0,1-3.884-1.893,4.93,4.93,0,0,1-6.742.979v3.06a4.93,4.93,0,0,0,6.742-.979,4.94,4.94,0,0,0,8.828-3.051l.37-12.006v-3.06l-.37,12.006a4.943,4.943,0,0,1-4.944,4.944Z" transform="translate(-247.229 -200.236)" opacity="0.2"/>
    <path id="Pfad_749" data-name="Pfad 749" d="M375.1,223.719a3.279,3.279,0,1,0-6.558,0v3.06a3.279,3.279,0,1,1,6.558,0Z" transform="translate(-264.034 -190.307)" opacity="0.2"/>
    <rect id="Rechteck_228" data-name="Rechteck 228" width="1.177" height="6.356" transform="translate(55.055 34.016)" fill="#3f3d56"/>
    <rect id="Rechteck_229" data-name="Rechteck 229" width="1.177" height="6.356" transform="translate(77.301 30.603)" fill="#3f3d56"/>
    <rect id="Rechteck_230" data-name="Rechteck 230" width="1.177" height="6.356" transform="translate(49.052 55.556)" fill="#3f3d56"/>
    <rect id="Rechteck_231" data-name="Rechteck 231" width="1.177" height="6.356" transform="matrix(0.822, 0.569, -0.569, 0.822, 97.34, 43.78)" fill="#3f3d56"/>
    <rect id="Rechteck_232" data-name="Rechteck 232" width="1.177" height="6.356" transform="translate(35.248 35.331) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_233" data-name="Rechteck 233" width="1.177" height="6.356" transform="translate(104.458 11.556) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_234" data-name="Rechteck 234" width="1.177" height="6.356" transform="translate(22.301 41.923) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_235" data-name="Rechteck 235" width="1.177" height="6.356" transform="translate(59.41 19.068)" fill="#fff"/>
    <rect id="Rechteck_236" data-name="Rechteck 236" width="1.177" height="6.356" transform="translate(27.159 51.79)" fill="#fff"/>
    <rect id="Rechteck_237" data-name="Rechteck 237" width="1.177" height="6.356" transform="translate(82.245 9.887)" fill="#fff"/>
    <rect id="Rechteck_238" data-name="Rechteck 238" width="1.177" height="6.356" transform="matrix(0.894, 0.448, -0.448, 0.894, 88.911, 31.619)" fill="#fff"/>
    <rect id="Rechteck_239" data-name="Rechteck 239" width="1.177" height="6.356" transform="matrix(0.894, 0.448, -0.448, 0.894, 83.496, 55.159)" fill="#fff"/>
    <rect id="Rechteck_240" data-name="Rechteck 240" width="1.177" height="6.356" transform="matrix(0.663, -0.748, 0.748, 0.663, 67.588, 43.648)" fill="#fff"/>
    <rect id="Rechteck_241" data-name="Rechteck 241" width="1.177" height="6.356" transform="matrix(0.663, -0.748, 0.748, 0.663, 44.282, 16.105)" fill="#fff"/>
    <rect id="Rechteck_242" data-name="Rechteck 242" width="1.177" height="6.356" transform="matrix(0.663, -0.748, 0.748, 0.663, 121.731, 26.698)" fill="#fff"/>
    <rect id="Rechteck_243" data-name="Rechteck 243" width="1.177" height="6.356" transform="matrix(0.663, -0.748, 0.748, 0.663, 93.247, 5.983)" fill="#fff"/>
    <rect id="Rechteck_244" data-name="Rechteck 244" width="1.177" height="6.356" transform="translate(68.356 30.603)" fill="#ff6584"/>
    <rect id="Rechteck_245" data-name="Rechteck 245" width="1.177" height="6.356" transform="matrix(0.92, 0.391, -0.391, 0.92, 134.853, 34.156)" fill="#ff6584"/>
    <rect id="Rechteck_246" data-name="Rechteck 246" width="1.177" height="6.356" transform="translate(44.953 41.446) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_247" data-name="Rechteck 247" width="1.177" height="6.356" transform="translate(112.28 36.973) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_248" data-name="Rechteck 248" width="1.177" height="6.356" transform="translate(70.378 13.668) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_249" data-name="Rechteck 249" width="1.177" height="6.356" transform="translate(120.284 12.491) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_250" data-name="Rechteck 250" width="1.177" height="6.356" transform="translate(55.547 7.312) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_251" data-name="Rechteck 251" width="1.177" height="6.356" transform="translate(97.782 17.891)" fill="#ff6584"/>
    <rect id="Rechteck_252" data-name="Rechteck 252" width="1.177" height="6.356" transform="translate(29.984 28.484)" fill="#ff6584"/>
    <path id="Pfad_750" data-name="Pfad 750" d="M365.61,562.361a24.21,24.21,0,0,1-.525-33.7q-.287.269-.567.549a24.21,24.21,0,1,0,34.239,34.239q.28-.28.549-.567A24.21,24.21,0,0,1,365.61,562.361Z" transform="translate(-258.33 -348.499)" opacity="0.2"/>
    <path id="Pfad_751" data-name="Pfad 751" d="M402.577,541.337a13.471,13.471,0,1,0,0,19.044A13.471,13.471,0,0,0,402.577,541.337ZM400.153,557.5a10.041,10.041,0,0,1-14.195,0c-.247-.247-.476-.508-.7-.773a10.035,10.035,0,1,1,14.89.773Z" transform="translate(-269.699 -352.976)" opacity="0.2"/>
    <path id="Pfad_752" data-name="Pfad 752" d="M421.9,545.66l-2.848,2.848a1.473,1.473,0,0,1-2.514-1.072l-.325.324a1.749,1.749,0,0,1-2.474-2.474l.27-.27a1.472,1.472,0,0,1-.962-2.512l2.977-3.076.7-.7a.6.6,0,0,0-.844-.844l-.7.7-2.58,2.481a1.474,1.474,0,1,1-2.084-2.084l2.4-2.658,1.294-1.294a.978.978,0,1,0-1.382-1.382l-1.294,1.294-2.609,2.453a16.886,16.886,0,0,0-3.722-1.637,1.469,1.469,0,0,1-.41-.795l-.692.692a1.749,1.749,0,0,1-2.474-2.474l2.137-2.137a1.473,1.473,0,0,1-2.419-1.565l-1.71,1.71a1.749,1.749,0,1,1-2.474-2.474l4.072-4.072-.007-.007-4.072,4.072a1.749,1.749,0,1,1-2.474-2.474l1.7-1.7a1.473,1.473,0,0,1-1.543-2.426l1.935-1.935-.106-.106-3.477,3.477a1.749,1.749,0,1,1-2.474-2.474l1.542-1.542a1.474,1.474,0,1,1-2.084-2.084l2.294-2.294A24.219,24.219,0,0,1,421.9,545.661Z" transform="translate(-274.916 -341.137)" fill="#fff"/>
    <path id="Pfad_753" data-name="Pfad 753" d="M399.9,540.04a1.749,1.749,0,0,1,0-2.474l-.645.645a1.749,1.749,0,0,0,2.474,2.474l.645-.645a1.749,1.749,0,0,1-2.474,0Z" transform="translate(-279.536 -353.067)" opacity="0.2"/>
    <path id="Pfad_754" data-name="Pfad 754" d="M405,545.137a1.749,1.749,0,0,1,0-2.474l-.645.645a1.749,1.749,0,0,0,2.474,2.474l.645-.645A1.749,1.749,0,0,1,405,545.137Z" transform="translate(-282.152 -355.683)" opacity="0.2"/>
    <path id="Pfad_755" data-name="Pfad 755" d="M401.844,531.525A1.473,1.473,0,0,1,400.3,529.1l-.645.645a1.473,1.473,0,0,0,1.543,2.426Z" transform="translate(-279.782 -348.721)" opacity="0.2"/>
    <rect id="Rechteck_253" data-name="Rechteck 253" width="0.912" height="0.01" transform="translate(126.27 183.546) rotate(-45)" opacity="0.2"/>
    <path id="Pfad_756" data-name="Pfad 756" d="M392.8,521.6a1.474,1.474,0,0,1-2.084-2.084l-.645.645a1.474,1.474,0,0,0,2.084,2.084Z" transform="translate(-274.864 -343.803)" opacity="0.2"/>
    <rect id="Rechteck_254" data-name="Rechteck 254" width="0.912" height="0.15" transform="translate(121.705 178.981) rotate(-45)" opacity="0.2"/>
    <path id="Pfad_757" data-name="Pfad 757" d="M391.748,529.438a1.749,1.749,0,0,1,0-2.474l-.645.645a1.749,1.749,0,0,0,2.474,2.474l.645-.645A1.749,1.749,0,0,1,391.748,529.438Z" transform="translate(-275.351 -347.626)" opacity="0.2"/>
    <path id="Pfad_758" data-name="Pfad 758" d="M414.175,554.312a1.749,1.749,0,0,1,0-2.474l-.645.645A1.749,1.749,0,0,0,416,554.957l.645-.645a1.749,1.749,0,0,1-2.474,0Z" transform="translate(-286.861 -360.392)" opacity="0.2"/>
    <path id="Pfad_759" data-name="Pfad 759" d="M444.829,562.167a.6.6,0,1,0-.844-.844l-.645.645a.6.6,0,1,1,.844.844Z" transform="translate(-302.424 -365.171)" opacity="0.2"/>
    <path id="Pfad_760" data-name="Pfad 760" d="M416.454,545.8a1.473,1.473,0,0,1-2.419-1.565l-.645.645a1.473,1.473,0,0,0,2.419,1.565Z" transform="translate(-287.003 -356.489)" opacity="0.2"/>
    <path id="Pfad_761" data-name="Pfad 761" d="M432.515,563.039a1.473,1.473,0,0,1,0-2.084l2.4-2.658-.645.645-2.4,2.658a1.474,1.474,0,1,0,2.084,2.084l2.58-2.481.645-.645-2.58,2.481a1.473,1.473,0,0,1-2.084,0Z" transform="translate(-296.315 -363.706)" opacity="0.2"/>
    <path id="Pfad_762" data-name="Pfad 762" d="M441.633,573.756a1.469,1.469,0,0,1-.43-1.072l-.325.325a1.749,1.749,0,1,1-2.474-2.474l.27-.271a1.472,1.472,0,0,1-.962-2.512l2.977-3.076-.645.645-2.977,3.077a1.472,1.472,0,0,0,.962,2.512l-.27.27a1.749,1.749,0,0,0,2.474,2.474l.325-.325a1.473,1.473,0,0,0,2.514,1.072l.645-.645A1.474,1.474,0,0,1,441.633,573.756Z" transform="translate(-298.982 -366.98)" opacity="0.2"/>
    <path id="Pfad_763" data-name="Pfad 763" d="M423.267,557.91a1.47,1.47,0,0,0-1.637-1.637,1.469,1.469,0,0,1-.41-.8l-.645.645a2.825,2.825,0,0,1,2.047,2.432,1.474,1.474,0,0,0,2.084,0l2.609-2.453.645-.645-2.609,2.453A1.473,1.473,0,0,1,423.267,557.91Z" transform="translate(-290.74 -362.249)" opacity="0.2"/>
    <path id="Pfad_764" data-name="Pfad 764" d="M439.106,553.88a.978.978,0,0,0-1.382-1.383l-.645.645a.978.978,0,0,1,1.382,1.382Z" transform="translate(-299.21 -360.583)" opacity="0.2"/>
    <rect id="Rechteck_255" data-name="Rechteck 255" width="0.351" height="1.895" transform="translate(127.962 181.619) rotate(45)" fill="#3f3d56"/>
    <rect id="Rechteck_256" data-name="Rechteck 256" width="0.351" height="1.895" transform="translate(133.371 185.589) rotate(45)" fill="#3f3d56"/>
    <rect id="Rechteck_257" data-name="Rechteck 257" width="0.351" height="1.895" transform="translate(138.725 182.668) rotate(45)" fill="#3f3d56"/>
    <rect id="Rechteck_258" data-name="Rechteck 258" width="0.351" height="1.895" transform="translate(122.157 184.894) rotate(45)" fill="#3f3d56"/>
    <rect id="Rechteck_259" data-name="Rechteck 259" width="0.351" height="1.895" transform="matrix(0.179, 0.984, -0.984, 0.179, 134.817, 192.588)" fill="#3f3d56"/>
    <rect id="Rechteck_260" data-name="Rechteck 260" width="0.351" height="1.895" transform="matrix(0.179, 0.984, -0.984, 0.179, 142.605, 189.181)" fill="#3f3d56"/>
    <rect id="Rechteck_261" data-name="Rechteck 261" width="0.351" height="1.895" transform="translate(123.511 177.721) rotate(-6.648)" fill="#3f3d56"/>
    <rect id="Rechteck_262" data-name="Rechteck 262" width="0.351" height="1.895" transform="translate(119.392 176.381) rotate(-6.648)" fill="#3f3d56"/>
    <rect id="Rechteck_263" data-name="Rechteck 263" width="0.351" height="1.895" transform="translate(132.031 179.386) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_264" data-name="Rechteck 264" width="0.351" height="1.895" transform="translate(142.254 186.201) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_265" data-name="Rechteck 265" width="0.351" height="1.895" transform="translate(146.148 195.937) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_266" data-name="Rechteck 266" width="0.351" height="1.895" transform="translate(118.336 179.485) rotate(45)" fill="#fff"/>
    <rect id="Rechteck_267" data-name="Rechteck 267" width="0.351" height="1.895" transform="translate(135.604 188.249) rotate(71.644)" fill="#fff"/>
    <rect id="Rechteck_268" data-name="Rechteck 268" width="0.351" height="1.895" transform="translate(129.501 192.07) rotate(71.644)" fill="#fff"/>
    <rect id="Rechteck_269" data-name="Rechteck 269" width="0.351" height="1.895" transform="translate(128.575 186.29) rotate(-3.441)" fill="#fff"/>
    <rect id="Rechteck_270" data-name="Rechteck 270" width="0.351" height="1.895" transform="translate(129.469 175.572) rotate(-3.441)" fill="#fff"/>
    <rect id="Rechteck_271" data-name="Rechteck 271" width="0.351" height="1.895" transform="translate(131.486 183.704) rotate(45)" fill="#ff6584"/>
    <rect id="Rechteck_272" data-name="Rechteck 272" width="0.351" height="1.895" transform="translate(143.168 200.254) rotate(45)" fill="#ff6584"/>
    <rect id="Rechteck_273" data-name="Rechteck 273" width="0.351" height="1.895" transform="translate(145.115 191.979) rotate(45)" fill="#ff6584"/>
    <rect id="Rechteck_274" data-name="Rechteck 274" width="0.351" height="1.895" transform="matrix(0.966, 0.259, -0.259, 0.966, 124.268, 181.056)" fill="#ff6584"/>
    <rect id="Rechteck_275" data-name="Rechteck 275" width="0.351" height="1.895" transform="matrix(0.966, 0.259, -0.259, 0.966, 135.482, 180.559)" fill="#ff6584"/>
    <rect id="Rechteck_276" data-name="Rechteck 276" width="0.351" height="1.895" transform="matrix(0.966, 0.259, -0.259, 0.966, 133.696, 176.093)" fill="#ff6584"/>
    <rect id="Rechteck_277" data-name="Rechteck 277" width="0.351" height="1.895" transform="translate(123.844 175.169) rotate(45)" fill="#ff6584"/>
    <path id="Pfad_765" data-name="Pfad 765" d="M921.014,361.361a3.894,3.894,0,0,0-7.788,0V535.939a3.894,3.894,0,0,0,7.788,0V362.492" transform="translate(-543.584 -260.634)" fill="#3f3d56"/>
    <path id="Pfad_766" data-name="Pfad 766" d="M914.918,351.654a58.588,58.588,0,0,0-107.683,44.924H814.5c1.46,0,2.658,1.626,2.658,3.617h1.334c1.465,0,2.663,1.626,2.663,3.617s-1.2,3.617-2.663,3.617h-1.334a4.908,4.908,0,0,1-.093.9h6.085c1.465,0,2.663,1.631,2.663,3.617s-1.2,3.617-2.663,3.617h-5.812a58.578,58.578,0,0,0,97.578-63.908ZM875.072,400.2a24.181,24.181,0,0,1-11.936,3.125,25.083,25.083,0,0,1-2.507-.127,24.265,24.265,0,1,1,14.442-3Z" transform="translate(-487.811 -241.662)" fill="var(--fuse-primary)"/>
    <path id="Pfad_767" data-name="Pfad 767" d="M838.844,500.766a4.74,4.74,0,0,0,.159-1.217c0-1.989-1.2-3.616-2.662-3.616H833.85a2.55,2.55,0,0,1-2.169,1.53H830.35a4.75,4.75,0,0,1-.1.9h6.086A2.866,2.866,0,0,1,838.844,500.766Z" transform="translate(-501 -331.7)" opacity="0.2"/>
    <path id="Pfad_768" data-name="Pfad 768" d="M818.5,460.559a2.866,2.866,0,0,1,2.5,2.4,4.732,4.732,0,0,0,.159-1.217c0-1.989-1.2-3.616-2.662-3.616h-1.331c0-1.989-1.2-3.616-2.662-3.616h-7.265a58.5,58.5,0,0,1-2.646-16.39c-.01.433-.022.865-.022,1.3a58.548,58.548,0,0,0,2.668,17.524h7.265c1.464,0,2.662,1.627,2.662,3.616Z" transform="translate(-487.821 -302.027)" opacity="0.2"/>
    <path id="Pfad_769" data-name="Pfad 769" d="M876.612,495.773a58.42,58.42,0,0,1-43.746-19.629h-2.053a58.569,58.569,0,0,0,104.37-36.508c0-.366-.01-.73-.017-1.094a58.57,58.57,0,0,1-58.554,57.231Z" transform="translate(-501.287 -302.245)" opacity="0.2"/>
    <path id="Pfad_770" data-name="Pfad 770" d="M894.174,380.92a29.143,29.143,0,1,0,29.148,29.144A29.142,29.142,0,0,0,894.174,380.92Zm11.936,50.293a24.181,24.181,0,0,1-11.936,3.125,25.055,25.055,0,0,1-2.507-.127,24.265,24.265,0,1,1,14.442-3Z" transform="translate(-518.851 -272.671)" opacity="0.2"/>
    <path id="Pfad_771" data-name="Pfad 771" d="M923.164,349.636v9.743a3.563,3.563,0,0,1-6.136,2.467v1.11a4.232,4.232,0,0,1-8.465,0v-.925a3.561,3.561,0,0,1-5.942-2.652l-.17-10.356v-2.389a1.443,1.443,0,1,0-2.886,0v2.389l-.17,8.658a3.565,3.565,0,1,1-7.13,0l-.436-8.658V344.6a2.365,2.365,0,0,0-4.73,0v4.426l-.266,8.658a30.953,30.953,0,0,1-11.229,2.906v2.368a4.232,4.232,0,1,1-8.465,0v-7.312a3.564,3.564,0,0,1-6.815,1.462v5.85a4.232,4.232,0,1,1-8.465,0V349.023h-.024v13.933a4.232,4.232,0,0,1-8.465,0v-5.8a3.563,3.563,0,0,1-6.791-1.511v-6.621h-.363v11.9a4.232,4.232,0,0,1-8.465,0v-5.275a3.565,3.565,0,0,1-7.13,0V347.8a58.591,58.591,0,0,1,102.541,1.84Z" transform="translate(-496.057 -240.617)" fill="#fff"/>
    <path id="Pfad_772" data-name="Pfad 772" d="M871.587,409.913a4.232,4.232,0,0,1-4.232-4.233v2.207a4.232,4.232,0,0,0,8.465,0v-2.207a4.232,4.232,0,0,1-4.233,4.232Z" transform="translate(-520.041 -285.379)" opacity="0.2"/>
    <path id="Pfad_773" data-name="Pfad 773" d="M889.024,409.913a4.232,4.232,0,0,1-4.232-4.233v2.207a4.232,4.232,0,1,0,8.465,0v-2.207a4.232,4.232,0,0,1-4.233,4.232Z" transform="translate(-528.991 -285.379)" opacity="0.2"/>
    <path id="Pfad_774" data-name="Pfad 774" d="M860.194,392.171a3.563,3.563,0,0,1-6.791-1.511v2.207a3.563,3.563,0,0,0,6.791,1.511Z" transform="translate(-512.881 -277.67)" opacity="0.2"/>
    <rect id="Rechteck_278" data-name="Rechteck 278" width="0.024" height="2.207" transform="translate(355.778 106.369)" opacity="0.2"/>
    <path id="Pfad_775" data-name="Pfad 775" d="M827.749,390.66a3.565,3.565,0,0,1-7.13,0v2.207a3.565,3.565,0,0,0,7.13,0Z" transform="translate(-496.055 -277.67)" opacity="0.2"/>
    <rect id="Rechteck_279" data-name="Rechteck 279" width="0.363" height="2.207" transform="translate(340.159 106.369)" opacity="0.2"/>
    <path id="Pfad_776" data-name="Pfad 776" d="M839.5,405.728a4.232,4.232,0,0,1-4.232-4.233V403.7a4.232,4.232,0,0,0,8.465,0V401.5a4.232,4.232,0,0,1-4.233,4.232Z" transform="translate(-503.573 -283.231)" opacity="0.2"/>
    <path id="Pfad_777" data-name="Pfad 777" d="M920.414,409.913a4.232,4.232,0,0,1-4.232-4.233v2.207a4.232,4.232,0,1,0,8.465,0v-2.207a4.232,4.232,0,0,1-4.233,4.232Z" transform="translate(-545.101 -285.379)" opacity="0.2"/>
    <path id="Pfad_778" data-name="Pfad 778" d="M985.683,370.629a1.443,1.443,0,1,0-2.886,0v2.207a1.443,1.443,0,1,1,2.886,0Z" transform="translate(-579.29 -266.649)" opacity="0.2"/>
    <path id="Pfad_779" data-name="Pfad 779" d="M909,390.66a3.564,3.564,0,0,1-6.815,1.462v2.207A3.564,3.564,0,0,0,909,392.867Z" transform="translate(-537.916 -277.67)" opacity="0.2"/>
    <path id="Pfad_780" data-name="Pfad 780" d="M970.905,389.281a3.565,3.565,0,0,1-3.565-3.566l-.436-8.658v2.207l.436,8.658a3.565,3.565,0,0,0,7.13,0l.17-8.658v-2.207l-.17,8.658a3.565,3.565,0,0,1-3.566,3.565Z" transform="translate(-571.133 -270.689)" opacity="0.2"/>
    <path id="Pfad_781" data-name="Pfad 781" d="M1005.871,390.979a3.554,3.554,0,0,1-2.57-1.1v1.11a4.232,4.232,0,0,1-8.465,0v-.925a3.561,3.561,0,0,1-5.942-2.652l-.17-10.356v2.207l.17,10.356a3.561,3.561,0,0,0,5.942,2.652v.925a4.232,4.232,0,1,0,8.465,0v-1.11a3.563,3.563,0,0,0,6.136-2.467v-2.207a3.565,3.565,0,0,1-3.566,3.565Z" transform="translate(-582.332 -270.689)" opacity="0.2"/>
    <path id="Pfad_782" data-name="Pfad 782" d="M941.235,389.281a6.834,6.834,0,0,0-7.663-.659v2.207a10.584,10.584,0,0,1,8.83.463,3.562,3.562,0,0,0,2.4-3.37l.266-8.658v-2.207l-.266,8.658a3.565,3.565,0,0,1-3.566,3.565Z" transform="translate(-554.026 -270.689)" opacity="0.2"/>
    <path id="Pfad_783" data-name="Pfad 783" d="M961.916,365.473a2.365,2.365,0,0,0-4.73,0v2.207a2.365,2.365,0,1,1,4.73,0Z" transform="translate(-566.146 -263.529)" opacity="0.2"/>
    <rect id="Rechteck_280" data-name="Rechteck 280" width="0.849" height="4.584" transform="translate(355.377 102.379)" fill="#3f3d56"/>
    <rect id="Rechteck_281" data-name="Rechteck 281" width="0.849" height="4.584" transform="translate(371.42 99.918)" fill="#3f3d56"/>
    <rect id="Rechteck_282" data-name="Rechteck 282" width="0.849" height="4.584" transform="translate(416.239 102.634)" fill="#3f3d56"/>
    <rect id="Rechteck_283" data-name="Rechteck 283" width="0.849" height="4.584" transform="translate(351.048 117.913)" fill="#3f3d56"/>
    <rect id="Rechteck_284" data-name="Rechteck 284" width="0.849" height="4.584" transform="matrix(0.822, 0.569, -0.569, 0.822, 385.87, 109.421)" fill="#3f3d56"/>
    <rect id="Rechteck_285" data-name="Rechteck 285" width="0.849" height="4.584" transform="translate(341.092 103.329) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_286" data-name="Rechteck 286" width="0.849" height="4.584" transform="translate(391.004 86.183) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_287" data-name="Rechteck 287" width="0.849" height="4.584" transform="translate(331.755 108.082) rotate(-51.648)" fill="#3f3d56"/>
    <rect id="Rechteck_288" data-name="Rechteck 288" width="0.849" height="4.584" transform="translate(358.518 91.599)" fill="#fff"/>
    <rect id="Rechteck_289" data-name="Rechteck 289" width="0.849" height="4.584" transform="translate(335.259 115.197)" fill="#fff"/>
    <rect id="Rechteck_290" data-name="Rechteck 290" width="0.849" height="4.584" transform="translate(374.985 84.978)" fill="#fff"/>
    <rect id="Rechteck_291" data-name="Rechteck 291" width="0.849" height="4.584" transform="translate(415.73 113.669)" fill="#fff"/>
    <rect id="Rechteck_292" data-name="Rechteck 292" width="0.849" height="4.584" transform="matrix(0.894, 0.448, -0.448, 0.894, 379.792, 100.652)" fill="#fff"/>
    <rect id="Rechteck_293" data-name="Rechteck 293" width="0.849" height="4.584" transform="matrix(0.894, 0.448, -0.448, 0.894, 375.887, 117.629)" fill="#fff"/>
    <rect id="Rechteck_294" data-name="Rechteck 294" width="0.849" height="4.584" transform="matrix(0.894, 0.448, -0.448, 0.894, 422.742, 109.988)" fill="#fff"/>
    <rect id="Rechteck_295" data-name="Rechteck 295" width="0.849" height="4.584" transform="matrix(0.663, -0.748, 0.748, 0.663, 364.414, 109.325)" fill="#fff"/>
    <rect id="Rechteck_296" data-name="Rechteck 296" width="0.849" height="4.584" transform="matrix(0.663, -0.748, 0.748, 0.663, 347.606, 89.462)" fill="#fff"/>
    <rect id="Rechteck_297" data-name="Rechteck 297" width="0.849" height="4.584" transform="matrix(0.663, -0.748, 0.748, 0.663, 403.461, 97.102)" fill="#fff"/>
    <rect id="Rechteck_298" data-name="Rechteck 298" width="0.849" height="4.584" transform="matrix(0.663, -0.748, 0.748, 0.663, 382.919, 82.162)" fill="#fff"/>
    <rect id="Rechteck_299" data-name="Rechteck 299" width="0.849" height="4.584" transform="translate(364.969 99.918)" fill="#ff6584"/>
    <rect id="Rechteck_300" data-name="Rechteck 300" width="0.849" height="4.584" transform="matrix(0.92, 0.391, -0.391, 0.92, 412.929, 102.48)" fill="#ff6584"/>
    <rect id="Rechteck_301" data-name="Rechteck 301" width="0.849" height="4.584" transform="translate(348.093 107.738) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_302" data-name="Rechteck 302" width="0.849" height="4.584" transform="translate(396.648 104.512) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_303" data-name="Rechteck 303" width="0.849" height="4.584" transform="translate(366.428 87.705) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_304" data-name="Rechteck 304" width="0.849" height="4.584" transform="translate(402.42 86.856) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_305" data-name="Rechteck 305" width="0.849" height="4.584" transform="translate(355.733 83.121) rotate(-30)" fill="#ff6584"/>
    <rect id="Rechteck_306" data-name="Rechteck 306" width="0.849" height="4.584" transform="translate(386.19 90.75)" fill="#ff6584"/>
    <rect id="Rechteck_307" data-name="Rechteck 307" width="0.849" height="4.584" transform="translate(337.296 98.39)" fill="#ff6584"/>
    <circle id="Ellipse_165" data-name="Ellipse 165" cx="20.45" cy="20.45" r="20.45" transform="translate(151.991 224.967)" fill="#2f2e41"/>
    <rect id="Rechteck_308" data-name="Rechteck 308" width="6.213" height="11.131" transform="translate(186.454 270.501) rotate(169.739)" fill="#2f2e41"/>
    <rect id="Rechteck_309" data-name="Rechteck 309" width="6.213" height="11.131" transform="translate(174.227 272.715) rotate(169.739)" fill="#2f2e41"/>
    <ellipse id="Ellipse_166" data-name="Ellipse 166" cx="5.177" cy="1.941" rx="5.177" ry="1.941" transform="matrix(0.763, -0.646, 0.646, 0.763, 164.898, 276.513)" fill="#2f2e41"/>
    <ellipse id="Ellipse_167" data-name="Ellipse 167" cx="5.177" cy="1.941" rx="5.177" ry="1.941" transform="matrix(0.763, -0.646, 0.646, 0.763, 176.87, 274.345)" fill="#2f2e41"/>
    <circle id="Ellipse_168" data-name="Ellipse 168" cx="6.989" cy="6.989" r="6.989" transform="translate(164.02 233.426)" fill="#fff"/>
    <circle id="Ellipse_169" data-name="Ellipse 169" cx="2.33" cy="2.33" r="2.33" transform="translate(165.807 235.58)" fill="#3f3d56"/>
    <path id="Pfad_784" data-name="Pfad 784" d="M509.325,601.892c.307-7.571-6.218-13.984-14.574-14.323s-15.38,5.523-15.688,13.094,5.5,9.291,13.858,9.631S509.018,609.464,509.325,601.892Z" transform="translate(-320.752 -378.723)" fill="#889eb5"/>
    <ellipse id="Ellipse_170" data-name="Ellipse 170" cx="3.21" cy="10.225" rx="3.21" ry="10.225" transform="matrix(0.223, -0.975, 0.975, 0.223, 142.873, 242.536)" fill="#2f2e41"/>
    <ellipse id="Ellipse_171" data-name="Ellipse 171" cx="3.21" cy="10.225" rx="3.21" ry="10.225" transform="matrix(0.223, -0.975, 0.975, 0.223, 183.017, 249.211)" fill="#2f2e41"/>
    <path id="Pfad_785" data-name="Pfad 785" d="M509.9,676.709a4.66,4.66,0,0,1-9.17,1.66c-.458-2.532,1.5-3.428,4.032-3.887S509.445,674.177,509.9,676.709Z" transform="translate(-331.845 -423.233)" fill="#fff"/>
    <ellipse id="Ellipse_172" data-name="Ellipse 172" cx="10.482" cy="3.291" rx="10.482" ry="3.291" transform="matrix(0.357, -0.934, 0.934, 0.357, 278.788, 181.912)" fill="#2f2e41"/>
    <circle id="Ellipse_173" data-name="Ellipse 173" cx="20.964" cy="20.964" r="20.964" transform="translate(252.429 228.4)" fill="#2f2e41"/>
    <rect id="Rechteck_310" data-name="Rechteck 310" width="6.369" height="11.411" transform="translate(263.84 265.816)" fill="#2f2e41"/>
    <rect id="Rechteck_311" data-name="Rechteck 311" width="6.369" height="11.411" transform="translate(276.577 265.816)" fill="#2f2e41"/>
    <ellipse id="Ellipse_174" data-name="Ellipse 174" cx="5.307" cy="1.99" rx="5.307" ry="1.99" transform="translate(263.84 275.369)" fill="#2f2e41"/>
    <ellipse id="Ellipse_175" data-name="Ellipse 175" cx="5.307" cy="1.99" rx="5.307" ry="1.99" transform="translate(276.577 275.103)" fill="#2f2e41"/>
    <circle id="Ellipse_176" data-name="Ellipse 176" cx="7.165" cy="7.165" r="7.165" transform="translate(266.759 236.891)" fill="#fff"/>
    <circle id="Ellipse_177" data-name="Ellipse 177" cx="2.388" cy="2.388" r="2.388" transform="translate(271.535 241.668)" fill="#3f3d56"/>
    <path id="Pfad_786" data-name="Pfad 786" d="M673.391,612.293c-1.693-7.581,3.718-15.241,12.086-17.109s16.523,2.762,18.216,10.343-3.853,10.377-12.22,12.245S675.084,619.874,673.391,612.293Z" transform="translate(-420.334 -382.426)" fill="#889eb5"/>
    <circle id="Ellipse_178" data-name="Ellipse 178" cx="20.964" cy="20.964" r="20.964" transform="translate(245.264 168.161)" fill="#2f2e41"/>
    <rect id="Rechteck_312" data-name="Rechteck 312" width="6.369" height="11.411" transform="translate(256.675 205.578)" fill="#2f2e41"/>
    <rect id="Rechteck_313" data-name="Rechteck 313" width="6.369" height="11.411" transform="translate(269.413 205.578)" fill="#2f2e41"/>
    <ellipse id="Ellipse_179" data-name="Ellipse 179" cx="5.307" cy="1.99" rx="5.307" ry="1.99" transform="translate(256.675 215.131)" fill="#2f2e41"/>
    <ellipse id="Ellipse_180" data-name="Ellipse 180" cx="5.307" cy="1.99" rx="5.307" ry="1.99" transform="translate(269.413 214.866)" fill="#2f2e41"/>
    <circle id="Ellipse_181" data-name="Ellipse 181" cx="7.165" cy="7.165" r="7.165" transform="translate(259.594 176.653)" fill="#fff"/>
    <circle id="Ellipse_182" data-name="Ellipse 182" cx="2.388" cy="2.388" r="2.388" transform="translate(264.37 181.43)" fill="#3f3d56"/>
    <path id="Pfad_787" data-name="Pfad 787" d="M658.672,488.543c-1.693-7.581,3.718-15.241,12.086-17.109s16.523,2.762,18.216,10.343-3.853,10.377-12.22,12.245S660.365,496.124,658.672,488.543Z" transform="translate(-412.78 -318.913)" fill="#889eb5"/>
    <ellipse id="Ellipse_183" data-name="Ellipse 183" cx="3.291" cy="10.482" rx="3.291" ry="10.482" transform="translate(231.736 177.879) rotate(-64.626)" fill="#2f2e41"/>
    <path id="Pfad_788" data-name="Pfad 788" d="M679.621,559.925c0,2.052,5.283,6.1,11.145,6.1s11.359-5.776,11.359-7.828-5.5.4-11.359.4S679.621,557.873,679.621,559.925Z" transform="translate(-423.69 -363.237)" fill="#fff"/>
    <circle id="Ellipse_184" data-name="Ellipse 184" cx="2.921" cy="2.921" r="2.921" transform="translate(324.527 212.08)" fill="var(--fuse-primary)"/>
    <circle id="Ellipse_185" data-name="Ellipse 185" cx="2.921" cy="2.921" r="2.921" transform="translate(332.482 188.502)" fill="var(--fuse-primary)"/>
    <circle id="Ellipse_186" data-name="Ellipse 186" cx="2.921" cy="2.921" r="2.921" transform="translate(325.208 176.451)" fill="var(--fuse-primary)"/>
    <rect id="Rechteck_314" data-name="Rechteck 314" width="5.896" height="10.564" transform="translate(304.488 266.813) rotate(-105.453)" fill="#2f2e41"/>
    <ellipse id="Ellipse_187" data-name="Ellipse 187" cx="1.843" cy="4.913" rx="1.843" ry="4.913" transform="translate(299.228 258.834) rotate(-15.453)" fill="#2f2e41"/>
    <ellipse id="Ellipse_188" data-name="Ellipse 188" cx="9.704" cy="3.046" rx="9.704" ry="3.046" transform="translate(327.716 250.381) rotate(-82.281)" fill="#2f2e41"/>
    <circle id="Ellipse_189" data-name="Ellipse 189" cx="19.408" cy="19.408" r="19.408" transform="translate(310.732 239.934)" fill="#2f2e41"/>
    <rect id="Rechteck_315" data-name="Rechteck 315" width="5.896" height="10.564" transform="translate(304.488 263.373) rotate(-105.453)" fill="#2f2e41"/>
    <ellipse id="Ellipse_190" data-name="Ellipse 190" cx="1.843" cy="4.913" rx="1.843" ry="4.913" transform="translate(299.228 255.395) rotate(-15.453)" fill="#2f2e41"/>
    <circle id="Ellipse_191" data-name="Ellipse 191" cx="6.633" cy="6.633" r="6.633" transform="translate(328.602 241.591)" fill="#fff"/>
    <circle id="Ellipse_192" data-name="Ellipse 192" cx="2.211" cy="2.211" r="2.211" transform="translate(335.146 243.083)" fill="#3f3d56"/>
    <path id="Pfad_789" data-name="Pfad 789" d="M868.185,688.436c7.182-.359,12.683-7.077,12.286-15s-6.541-14.062-13.723-13.7-8.309,6-7.912,13.925S861,688.8,868.185,688.436Z" transform="translate(-515.634 -415.757)" fill="#889eb5"/>
    <path id="Pfad_790" data-name="Pfad 790" d="M810.739,665.142a4.422,4.422,0,0,1-.714-8.815c2.434-.2,3.1,1.736,3.3,4.17S813.173,664.945,810.739,665.142Z" transform="translate(-488.532 -414.012)" fill="#fff"/>
    <ellipse id="Ellipse_193" data-name="Ellipse 193" cx="9.704" cy="3.046" rx="9.704" ry="3.046" transform="translate(320.431 254.992) rotate(-82.281)" fill="#2f2e41"/>
    <circle id="Ellipse_194" data-name="Ellipse 194" cx="2.921" cy="2.921" r="2.921" transform="translate(323.067 231.064)" fill="var(--fuse-primary)"/>
    <path id="Pfad_791" data-name="Pfad 791" d="M763.463,491.241a3.628,3.628,0,0,0-3.62-4.1h-1.157a3.616,3.616,0,0,0,.426-1.7,3.662,3.662,0,0,0-3.651-3.651H743.292a3.651,3.651,0,0,0,0,7.3h1.157a3.617,3.617,0,0,0-.426,1.7,11.241,11.241,0,0,1,3.343,6.319,3.628,3.628,0,0,0,2.742,1.226h12.169a3.648,3.648,0,0,0,1.187-7.1Z" transform="translate(-454.495 -324.441)" fill="var(--fuse-primary)"/>
    <rect id="Rechteck_316" data-name="Rechteck 316" width="0.373" height="2.016" transform="translate(145.409 192.916) rotate(45)" fill="#3f3d56"/>
  </g>
</svg>
