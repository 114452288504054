<iframe
  *ngIf="currentQuery"
  height="100%"
  width="100%"
  style="overflow:hidden; height:100%; width:100%; display: unset"
  loading="lazy"
  allowfullscreen
  class="bg-gray-200"
  (load)="isLoaded = true"
  [ngClass]="{ 'animate-pulse': !isLoaded }"
  [src]="url">
</iframe>

<div *ngIf="!currentQuery" class="w-full h-full flex items-center justify-center bg-primary-50">
  <mat-icon class="text-6xl">wrong_location</mat-icon>
</div>
