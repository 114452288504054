<fuse-card class="flex flex-col p-4 px-8 overflow-visible">
  <h2 class="text-2xl font-bold mb-4">Notiz erstellen</h2>
  <div class="flex flex-row">

    <div class="w-full">
      <div class="mb-4">
        <quill-editor
          [(ngModel)]="content"
          [modules]="modules"
          placeholder="Notiz schreiben...">
        </quill-editor>
      </div>
      <div class="flex justify-end">
        <button (click)="save()" color="primary" mat-raised-button>Teilen</button>
      </div>
    </div>
  </div>
</fuse-card>

<app-note-item
  (delete)="delete($event)"
  (reload)="reload$.next()"
  *ngFor="let note of notes"
  [note]="note"></app-note-item>
