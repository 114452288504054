import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MinionsNotFoundComponent } from './svg/minions-not-found/minions-not-found.component';
import { MapsComponent } from './components/maps/maps.component';
import { MinionsClimbingWindowComponent } from './svg/minions-climbing-window/minions-climbing-window.component';
import { MatIconModule } from '@angular/material/icon';
import { MinionsEatingDonutComponent } from './svg/minions-eating-donut/minions-eating-donut.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MinionsSearchingWorldComponent } from './svg/minions-searching-world/minions-searching-world.component';
import { MinionsBuildingBrowserComponent } from './svg/minions-building-browser/minions-building-browser.component';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { MinionsHeartComponent } from './svg/minions-heart/minions-heart.component';
import { CurrencyAutocompleteComponent } from './components/form-fields/currency-autocomplete/currency-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ContactAutocompleteComponent } from './components/form-fields/contact-autocomplete/contact-autocomplete.component';
import { BankAccountAutocompleteComponent } from './components/form-fields/bank-account-autocomplete/bank-account-autocomplete.component';
import { CampaignAutocompleteComponent } from './components/form-fields/campaign-autocomplete/campaign-autocomplete.component';
import { DonationPurposeAutocompleteComponent } from './components/form-fields/donation-purpose-autocomplete/donation-purpose-autocomplete.component';
import { TransactionSumPipe } from './pipes/transaction-sum/transaction-sum.pipe';
import { MinionsUfoComponent } from './svg/minions-ufo/minions-ufo.component';
import { FileDropDirective } from './directives/file-drop/file-drop.directive';
import { TransactionCurrencyPipe } from './pipes/transaction-currency/transaction-currency.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json/pretty-json.pipe';
import { AccountAutocompleteComponent } from './components/form-fields/account-autocomplete/account-autocomplete.component';
import { GodchildAutocompleteComponent } from './components/form-fields/godchild-autocomplete/godchild-autocomplete.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { MatMenuModule } from '@angular/material/menu';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { AddOrUpdateComponent } from './components/custom-fields/add-or-update-custom-field/add-or-update.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AddOrUpdateValuesComponent } from './components/custom-fields/add-or-update-values/add-or-update-values.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotesComponent } from './components/notes/notes.component';
import { QuillModule } from 'ngx-quill';
import { FuseCardModule } from '../../@fuse/components/card';
import { UserAccountIconComponent } from './components/user-account-icon/user-account-icon.component';
import { RelativeDatePipe } from './pipes/relative-date/relative-date.pipe';
import { NoteItemComponent } from './components/notes/note-item/note-item.component';
import { SponsorshipCardComponent } from './components/sponsorship-card/sponsorship-card.component';
import { GodchildAvatarComponent } from './components/godchild-avatar/godchild-avatar.component';
import { GenderPipe } from './pipes/gender/gender.pipe';
import { PaymentCyclePipe } from './pipes/payment-cycle/payment-cycle.pipe';
import { SponsorshipStatusPipe } from './pipes/sponsorship-status/sponsorship-status.pipe';
import { SponsorshipStatusComponent } from './components/sponsorship-status/sponsorship-status.component';
import { AddressComponent } from './components/address/address.component';
import { EditSponsorshipDialogComponent } from './components/sponsorship-card/edit-sponsorship-dialog/edit-sponsorship-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaymentCycleComponent } from './components/form-fields/payment-cycle/payment-cycle.component';
import { TransactionsCardComponent } from './components/transactions-card/transactions-card.component';
import { TransactionImportStatusComponent } from './components/transaction-import-status/transaction-import-status.component';
import { ScrollDirective } from './directives/scroll/scroll.directive';
import { DisplayNamePipe } from './pipes/display-name/display-name.pipe';
import { TransactionAmountPipe } from './pipes/transaction-amount/transaction-amount.pipe';
import { GenderAutocompleteComponent } from './components/form-fields/gender-autocomplete/gender-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DragDropModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    QuillModule,
    FuseCardModule,
    MatDatepickerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MinionsNotFoundComponent,
    MapsComponent,
    MinionsClimbingWindowComponent,
    MinionsEatingDonutComponent,
    DialogComponent,
    MinionsSearchingWorldComponent,
    MinionsBuildingBrowserComponent,
    OrderByPipe,
    MinionsHeartComponent,
    CurrencyAutocompleteComponent,
    ContactAutocompleteComponent,
    BankAccountAutocompleteComponent,
    CampaignAutocompleteComponent,
    DonationPurposeAutocompleteComponent,
    TransactionSumPipe,
    MinionsUfoComponent,
    FileDropDirective,
    TransactionCurrencyPipe,
    PrettyJsonPipe,
    GodchildAutocompleteComponent,
    AccountAutocompleteComponent,
    CustomFieldsComponent,
    NotesComponent,
    SponsorshipCardComponent,
    GodchildAvatarComponent,
    SponsorshipStatusComponent,
    UserAccountIconComponent,
    AddressComponent,
    PaymentCycleComponent,
    TransactionsCardComponent,
    ScrollDirective,
    DisplayNamePipe,
    TransactionImportStatusComponent,
    TransactionAmountPipe,
    GenderAutocompleteComponent
  ],
  declarations: [
    MinionsNotFoundComponent,
    MapsComponent,
    MinionsClimbingWindowComponent,
    MinionsEatingDonutComponent,
    DialogComponent,
    MinionsSearchingWorldComponent,
    MinionsBuildingBrowserComponent,
    OrderByPipe,
    MinionsHeartComponent,
    CurrencyAutocompleteComponent,
    ContactAutocompleteComponent,
    BankAccountAutocompleteComponent,
    CampaignAutocompleteComponent,
    DonationPurposeAutocompleteComponent,
    TransactionSumPipe,
    MinionsUfoComponent,
    FileDropDirective,
    TransactionCurrencyPipe,
    PrettyJsonPipe,
    AccountAutocompleteComponent,
    GodchildAutocompleteComponent,
    CustomFieldsComponent,
    AddOrUpdateComponent,
    AddOrUpdateValuesComponent,
    NotesComponent,
    UserAccountIconComponent,
    RelativeDatePipe,
    NoteItemComponent,
    SponsorshipCardComponent,
    GodchildAvatarComponent,
    GenderPipe,
    PaymentCyclePipe,
    SponsorshipStatusPipe,
    SponsorshipStatusComponent,
    AddressComponent,
    EditSponsorshipDialogComponent,
    PaymentCycleComponent,
    TransactionsCardComponent,
    TransactionImportStatusComponent,
    ScrollDirective,
    DisplayNamePipe,
    TransactionAmountPipe,
    GenderAutocompleteComponent
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'shared/components/custom-field', alias: 'customFields' },
      multi: true
    }
  ]
})
export class SharedModule {
}
