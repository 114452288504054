import { Component, Input } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import { UserDto } from '@donately/app-frontend-library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-account-icon',
  templateUrl: './user-account-icon.component.html'
})
export class UserAccountIconComponent {

  @Input() user: UserDto;
  @Input() current = false;

  constructor(
    public authService: AuthService,
    private _router: Router
  ) {
  }

  goToUser(): void {
    this._router.navigate(['contacts', this.user.id]);
  }

}
