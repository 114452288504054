import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { AddOrUpdateComponent } from '../add-or-update-custom-field/add-or-update.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder } from '@angular/forms';
import {
  AddOrUpdateCustomFieldDataAction,
  CustomFieldDefinitionDto,
  CustomFieldDonatelyService
} from '@donately/app-frontend-library';
import { FuseConfirmationService } from '../../../../../@fuse/services/confirmation';
import { take } from 'rxjs/operators';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-add-or-update-values',
  templateUrl: './add-or-update-values.component.html'
})
export class AddOrUpdateValuesComponent {

  customFieldsForm: FormArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { group: string; customFields: CustomFieldDefinitionDto[]; identifier: string },
    @Inject(TRANSLOCO_SCOPE) private _scope: string[],
    private _dialog: MatDialog,
    private _matDialogRef: MatDialogRef<AddOrUpdateValuesComponent>,
    private _customFieldService: CustomFieldDonatelyService,
    private _fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private fuseDialog: FuseConfirmationService,
    private _translocoService: TranslocoService
  ) {
    this.customFieldsForm = _fb.array([]);
    this.updateForm('populate');
  }

  save(): void {
    const dataToUpdate: AddOrUpdateCustomFieldDataAction[] = [];
    for (const control of this.customFieldsForm.controls) {
      dataToUpdate.push({
        customFieldDataId: control.get('customFieldDataId').value,
        value: control.get('value').value,
        entityIdentifier: control.get('customFieldDataId').value ? null : this.data.identifier,
        customFieldDefinitionId: control.get('customFieldDataId').value ? null : control.get('id').value
      });
    }

    this._customFieldService.addOrUpdateCustomFieldData(dataToUpdate)
      .subscribe(() => this._matDialogRef.close());
  }

  openDeleteFieldDialog(id: string): void {
    this._translocoService.selectTranslateObject(
      'deleteCustomFieldDialog', {}, this._scope[0]
    )
      .pipe(take(1))
      .subscribe((dialogTranslation: {
        cancel: string;
        confirm: string;
        title: string;
        message: string;
      }) => {
        this.fuseDialog.open({
          icon: {
            name: 'warning',
            color: 'warning'
          },
          actions: {
            confirm: {
              color: 'warn',
              label: dialogTranslation.confirm
            },
            cancel: {
              label: dialogTranslation.cancel
            }
          },
          title: dialogTranslation.title,
          message: dialogTranslation.message
        }).afterClosed().pipe(take(1)).subscribe(result => {
          if (result === 'confirmed') {
            this.deleteField(id);
          }
        });
      });
  }

  addOrUpdateField(customFieldId: string = null): void {
    this._dialog.open(
      AddOrUpdateComponent,
      {
        data:
          {
            group: this.data.group,
            customField: this.data.customFields.find(c => c.id === customFieldId)
          }
      }
    )
      .afterClosed().subscribe((updatedField: CustomFieldDefinitionDto) => {
      if (!updatedField) {
        return;
      }

      const index = this.data.customFields.findIndex(c => c.id === updatedField.id);
      if (index < 0) {
        this.data.customFields.push(updatedField);
        this.updateForm('add', updatedField);
      } else {
        this.data.customFields.splice(index, 1, updatedField);
        this.updateForm('update', updatedField);
      }
    });
  }

  private deleteField(id: string): void {
    this.customFieldsForm.disable();
    this._customFieldService.deleteField(id)
      .subscribe(() => {
        this.data.customFields.splice(this.data.customFields.findIndex(c => c.id === id), 1);
        const index = this.customFieldsForm.controls.findIndex(c => c.get('id').value === id);
        this.customFieldsForm.removeAt(index);
        this.customFieldsForm.enable();
      }, () => this.customFieldsForm.enable());
  }

  private updateForm(action: 'populate' | 'add' | 'update', customFieldDefinition?: CustomFieldDefinitionDto): void {
    this._changeDetectorRef.detach();

    if (action === 'add') {
      this.customFieldsForm.push(this._fb.group({
        label: [customFieldDefinition?.label ?? ''],
        value: [customFieldDefinition?.customFieldData?.[0]?.value ?? ''],
        type: [customFieldDefinition?.type ?? ''],
        id: [customFieldDefinition.id],
        customFieldDataId: [customFieldDefinition?.customFieldData?.[0]?.id ?? '']
      }));
    }

    if (action === 'update') {
      const group = this.customFieldsForm.controls.find(c => c.get('id').value === customFieldDefinition.id);
      group.patchValue({
        label: customFieldDefinition.label
      });
    }

    if (action === 'populate') {
      this.customFieldsForm.clear();
      for (const field of this.data.customFields) {
        this.customFieldsForm.push(this._fb.group({
          label: [field?.label ?? ''],
          value: [field?.customFieldData?.[0]?.value ?? ''],
          type: [field?.type ?? ''],
          id: [field.id],
          customFieldDataId: [field?.customFieldData?.[0]?.id ?? '']
        }));
      }
    }

    this._changeDetectorRef.reattach();
  }

}
