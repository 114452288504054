import { Component } from '@angular/core';
import { AlertService } from './alert.service';
import { fuseAnimations } from '../../../@fuse/animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  animations: fuseAnimations
})
export class AlertComponent {

  constructor(public alertService: AlertService) {
  }

}
