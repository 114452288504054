import { Component, Inject } from '@angular/core';
import { GodchildDonatelyService, SponsorshipDto, SponsorshipStatus } from '@donately/app-frontend-library';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-sponsorship-dialog',
  templateUrl: './edit-sponsorship-dialog.component.html'
})
export class EditSponsorshipDialogComponent {

  form: FormGroup;
  sponsorshipStatus = SponsorshipStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) private sponsorship: SponsorshipDto,
    private _matDialogRef: MatDialogRef<EditSponsorshipDialogComponent>,
    private _contactService: GodchildDonatelyService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      targetAmount: [sponsorship.targetAmount],
      paymentCycle: [sponsorship.paymentCycle],
      endDate: [sponsorship.endDate],
      startDate: [sponsorship.startDate],
      godchild: [sponsorship.godchild],
      currency: [sponsorship.currency]
    });

    if (sponsorship.transactions?.length > 0) {
      this.form.get('godchild').disable();
      this.form.get('currency').disable();
    }
  }

  save(): void {
    const sponsorship: SponsorshipDto = {
      ...this.sponsorship,
      targetAmount: this.form.get('targetAmount').value,
      paymentCycle: this.form.get('paymentCycle').value,
      endDate: this.form.get('endDate').value,
      startDate: this.form.get('startDate').value,
      godchild: this.form.get('godchild').value,
      currency: this.form.get('currency').value
    };

    this._matDialogRef.close(sponsorship);
  }

}
