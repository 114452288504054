<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    #inputElement
    [formControl]="control"
    [matAutocomplete]="contactAutocomplete"
    matInput
    type="text">
  <mat-autocomplete
    #contactAutocomplete="matAutocomplete"
    (optionSelected)="optionSelected.emit($event.option.value)"
    [displayWith]="displayContact"
    autoActiveFirstOption>
    <mat-option
      *ngFor="let contact of contacts | async"
      [value]="contact">{{ displayContact(contact) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
