import { Component, Input } from '@angular/core';
import { Gender, GodchildDto } from '@donately/app-frontend-library';

@Component({
  selector: 'app-godchild-avatar',
  templateUrl: './godchild-avatar.component.html',
  styleUrls: ['./godchild-avatar.component.scss']
})
export class GodchildAvatarComponent {

  @Input() godchild: GodchildDto;
  gender = Gender;

}
