import { Pipe, PipeTransform } from '@angular/core';
import { TransactionDto } from '@donately/app-frontend-library';
import { transactionAmount } from '../../utils/transactios.utils';

@Pipe({
  name: 'transactionSum'
})
export class TransactionSumPipe implements PipeTransform {

  transform(transaction: TransactionDto): number {
    return transactionAmount(transaction);
  }

}
