import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective {

  @Output() fileDrop = new EventEmitter<FileList>();
  @Output() isDropZoneActive = new EventEmitter<boolean>();

  @HostBinding('class.drop-zone-active') isOverDropZone: boolean;

  @HostListener('drop', ['$event'])
  dropFile(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isOverDropZone = false;
    this.isDropZoneActive.emit(false);
    this.fileDrop.emit(event.dataTransfer.files);
  }

  @HostListener('dragover', ['$event'])
  public preventDragOverPropagation(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isOverDropZone = true;
    this.isDropZoneActive.emit(true);
  }

  @HostListener('dragenter', ['$event'])
  public preventDragEnterPropagation(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public preventDragLeavePropagation(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isOverDropZone = false;
    this.isDropZoneActive.emit(false);
  }
}
