/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    subtitle: 'Alles im Blick',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home'
      }
      // {
      //   id: 'analytics',
      //   title: 'Analytics',
      //   type: 'basic',
      //   icon: 'heroicons_outline:chart-pie',
      //   link: '/analytics'
      // }
    ]
  },
  {
    id: 'applications',
    title: 'Applikationen',
    subtitle: 'Rund um die Arbeit',
    type: 'group',
    children: [
      {
        id: 'transactions',
        title: 'Transaktionen',
        type: 'collapsable',
        icon: 'heroicons_outline:cash',
        link: '/transactions',
        children: [
          {
            id: 'transactions.list',
            title: 'Transaktionen',
            type: 'basic',
            link: '/transactions',
            exactMatch: true
          },
          {
            id: 'transactions.import',
            title: 'Import',
            type: 'basic',
            link: '/transactions/import'
          }
        ]
      },
      {
        id: 'contacts',
        title: 'Kontakte',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/contacts'
      },
      {
        id: 'godchildren',
        title: 'Patenkinder',
        type: 'basic',
        icon: 'heroicons_outline:globe',
        link: '/godchildren'
      },
      {
        id: 'sponsorships',
        title: 'Patenschaften',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/sponsorships'
      },
      {
        id: 'campaigns',
        title: 'Kampagnen',
        type: 'basic',
        icon: 'heroicons_outline:flag',
        link: '/campaigns'
      },
      {
        id: 'smart-grid',
        title: 'Smart Grid',
        type: 'basic',
        icon: 'heroicons_outline:cube-transparent',
        link: '/smart-grid'
      },
      {
        id: 'accounting',
        title: 'Buchhaltung',
        type: 'basic',
        icon: 'heroicons_outline:book-open',
        link: '/accounting'
      }
      // {
      //   id: 'documents',
      //   title: 'Dokumente',
      //   type: 'basic',
      //   icon: 'heroicons_outline:folder',
      //   link: '/documents'
      // },
      // {
      //   id: 'custom-forms',
      //   title: 'Formulare',
      //   type: 'basic',
      //   icon: 'heroicons_outline:template',
      //   link: '/documents'
      // }
    ]
  },
  // {
  //   id: 'administration',
  //   title: 'Administration',
  //   subtitle: 'Du und dein Team',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'tasks',
  //       title: 'Aufgaben',
  //       type: 'basic',
  //       icon: 'heroicons_outline:clipboard-check',
  //       link: '/tasks'
  //     }, {
  //       id: 'chat',
  //       title: 'Chat',
  //       type: 'basic',
  //       icon: 'heroicons_outline:chat',
  //       link: '/chat'
  //     }, {
  //       id: 'calendar',
  //       title: 'Kalender',
  //       type: 'basic',
  //       icon: 'heroicons_outline:calendar',
  //       link: '/calendar'
  //     }
  //   ]
  // },
  {
    id: 'einstellungen',
    title: 'Einstellungen',
    subtitle: 'Deine Organisation',
    type: 'group',
    children: [
      {
        id: 'settings',
        title: 'Allgemein',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/settings'
      },
      {
        id: 'users',
        title: 'Benutzerverwaltung',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/users'
      }
    ]
  }
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example'
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example'
  }
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example'
  }
];
