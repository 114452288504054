import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NoteDonatelyService, NoteDto } from '@donately/app-frontend-library';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FuseConfirmationService } from '../../../../../@fuse/services/confirmation';

@Component({
  selector: 'app-note-item',
  templateUrl: './note-item.component.html'
})
export class NoteItemComponent {

  @ViewChild(QuillEditorComponent) quillRef: QuillEditorComponent;

  @Input() note: NoteDto;

  @Output() delete = new EventEmitter<string>();
  @Output() reload = new EventEmitter<void>();

  editMode = false;
  content = '';

  modules: QuillModules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: unknown, insertItem: (item: unknown) => void): void => {
        const editor = this.quillRef.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm: string, renderList: (values: unknown[], searchTerm: string) => void): void => {
        const values = [
          { id: 1, value: 'Fredrik Sundqvist' },
          { id: 2, value: 'Remy Marthaler' },
          { id: 3, value: 'Manuela Quero' },
          { id: 4, value: 'Patrik Sjölin' }
        ];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      }
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['link']
    ]
  };

  constructor(
    private _noteService: NoteDonatelyService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private _fuseConfirmationService: FuseConfirmationService
  ) {
  }

  get sanitizedHtml(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.note.contentHtml);
  }

  edit(): void {
    this.editMode = true;
  }

  deleteNote(id: string): void {
    this._fuseConfirmationService.open({
      actions: {
        cancel: {
          label: 'Abbrechen'
        },
        confirm: {
          label: 'Löschen'
        }
      },
      title: 'Notiz löschen?',
      message: 'Soll diese Notiz unwiderruflich gelöscht werden?',
      dismissible: true
    }).afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.delete.emit(id);
      }
    });
  }

  initEditMode(): void {
    this.quillRef.quillEditor.setContents(JSON.parse(this.note.contentDelta));
    this._changeDetectorRef.detectChanges();
  }

  update(): void {
    this._noteService.updateNote({
      id: this.note.id,
      contentHtml: this.content ? this.content : this.note.contentHtml,
      contentDelta: JSON.stringify(this.quillRef.quillEditor.getContents())
    }).pipe(take(1)).subscribe(() => {
      this.editMode = false;
      this.reload.emit();
    });
  }

}
