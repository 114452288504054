import { Component, Input } from '@angular/core';
import { PaymentCycle } from '@donately/app-frontend-library';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-payment-cycle',
  templateUrl: './payment-cycle.component.html'
})
export class PaymentCycleComponent {

  @Input() labelName: string;
  @Input() control: FormControl;

  paymentCycle = PaymentCycle;

}
