import { Action } from '@ngrx/store';
import { Alert } from '../../alert/alert';

export enum UtilsActionTypes {
  OPEN_LAYOUT_DRAWER = '[CORE] Open LayoutDrawer',
  CLOSE_LAYOUT_DRAWER = '[CORE] Close LayoutDrawer',

  SHOW_ALERT = '[CORE] Show alert',
}

export class OpenLayoutDrawer implements Action {
  readonly type = UtilsActionTypes.OPEN_LAYOUT_DRAWER;
}

export class CloseLayoutDrawer implements Action {
  readonly type = UtilsActionTypes.CLOSE_LAYOUT_DRAWER;
}

export class ShowAlert implements Action {
  readonly type = UtilsActionTypes.SHOW_ALERT;

  constructor(public alert: Alert) {
  }
}


export type UtilsActions = OpenLayoutDrawer | CloseLayoutDrawer;
