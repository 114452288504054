import { Pipe, PipeTransform } from '@angular/core';
import { TransactionDto } from '@donately/app-frontend-library';
import { transactionCurrency } from '../../utils/transactios.utils';

@Pipe({
  name: 'transactionCurrency'
})
export class TransactionCurrencyPipe implements PipeTransform {

  transform(transaction: TransactionDto): unknown {
    return transactionCurrency(transaction);
  }

}
