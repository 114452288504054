<app-dialog
  *transloco="let t; read: 'customFields'"
  [title]="t('edit')">
  <ng-container content>

    <ng-container *ngFor="let group of customFieldsForm.controls">

      <div *ngIf="group.get('type').value !== 'Checkbox'" class="flex flex-row justify-between my-1">
        <div class="font-bold">{{group.get('label').value}}</div>
        <ng-container
          [ngTemplateOutletContext]="{ group: group }"
          [ngTemplateOutlet]="editButtons">
        </ng-container>
      </div>

      <mat-form-field *ngIf="group.get('type').value === 'Input'" class="w-full">
        <input [formControl]="group.get('value')" matInput type="text">
      </mat-form-field>

      <mat-form-field *ngIf="group.get('type').value === 'Textarea'" class="w-full">
        <textarea [formControl]="group.get('value')" matInput type="text"></textarea>
      </mat-form-field>

      <div *ngIf="group.get('type').value === 'Checkbox'" class="flex flex-row justify-between my-1">
        <mat-checkbox
          (change)="group.get('value').setValue($event.checked ? 'checked' : 'unchecked')"
          [checked]="group.get('value').value === 'checked'">{{group.get('label').value}}</mat-checkbox>
        <ng-container
          [ngTemplateOutletContext]="{ group: group }"
          [ngTemplateOutlet]="editButtons">
        </ng-container>
      </div>

    </ng-container>

    <div class="flex justify-center">
      <button (click)="addOrUpdateField()" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>

  </ng-container>
  <ng-container footer>

    <!-- Cancel -->
    <button mat-stroked-button matDialogClose>{{t('cancel')}}</button>

    <!-- Save button -->
    <button
      (click)="save()"
      [color]="'primary'"
      [disabled]="customFieldsForm.disabled"
      class="relative"
      mat-flat-button>
                    <span [ngClass]="{ 'invisible': customFieldsForm.disabled }">
                        {{t('save')}}
                    </span>
      <mat-progress-spinner
        *ngIf="customFieldsForm.disabled"
        [diameter]="18"
        [mode]="'indeterminate'"
        class="absolute m-auto"></mat-progress-spinner>
    </button>
  </ng-container>
</app-dialog>

<ng-template
  #editButtons
  let-group="group">
  <div
    *transloco="let t; read: 'customFields'"
    class="flex">
    <button
      (click)="addOrUpdateField(group.get('id').value)"
      [matTooltip]="t('editCustomField')"
      class="mat-icon-button-small"
      mat-icon-button
      matTooltipShowDelay="500">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      (click)="openDeleteFieldDialog(group.get('id').value)"
      [matTooltip]="t('deleteField')"
      class="mat-icon-button-small"
      mat-icon-button
      matTooltipShowDelay="500">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-template>
