import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AccountDto, AccountingDonatelyService } from '@donately/app-frontend-library';
import { filter, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-account-autocomplete',
  templateUrl: './account-autocomplete.component.html'
})
export class AccountAutocompleteComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() labelName: string;

  @ViewChild('inputElement') inputElement: ElementRef;

  accounts: Observable<AccountDto[]>;

  constructor(
    private _accountingService: AccountingDonatelyService
  ) {
  }

  ngOnInit(): void {
    this.accounts = this.control.valueChanges.pipe(
      filter(value => typeof value === 'string' || value instanceof String),
      startWith(''),
      switchMap(value => {
        return this._accountingService.getAccounts({ filterValue: value });
      })
    );
  }

  displayAccount(account: AccountDto): string {
    if (account?.name == null) {
      return;
    }

    return `${account?.accountNumber} ${account?.name}`;
  }

  focus(): void {
    this.inputElement.nativeElement.focus();
  }

}
