import { Directive, ElementRef, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  public readonly scrollBottom$ = new Subject<number>();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('scroll', ['$event'])
  handleScroll(event: Event): void {
    const target = event.target as HTMLElement;
    this.scrollBottom$.next(target.scrollHeight - target.scrollTop - target.clientHeight);
  }

  scrollToTop(): void {
    this._elementRef.nativeElement.scrollTo(0, 0);
  }

}
