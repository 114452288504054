import { Component, Input } from '@angular/core';
import { AddressDto } from '@donately/app-frontend-library';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html'
})
export class AddressComponent {

  @Input() address: AddressDto;

  get postalCodeWithTown(): string {
    if (!this.address.town && !this.address.postalCode) {
      return '';
    }

    if (this.address.town && this.address.postalCode) {
      return `${this.address.postalCode} ${this.address.town}`;
    }

    return `${this.address.postalCode || this.address.town}`;
  }

  get hasAddress(): boolean {
    return !!this.address?.firstLine
      || !!this.address?.secondLine
      || !!this.address?.postalCode
      || !!this.address?.country;
  }

}
