import { Injectable, OnDestroy } from '@angular/core';
import { Alert } from './alert';
import { Subject, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService implements OnDestroy {

  private _hide$ = new Subject();
  private _alive = true;

  constructor() {
    this._hide$.pipe(takeWhile(() => this._alive)).subscribe(() => this._alerts.pop());
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _alerts: Alert[] = [];

  get alerts(): Alert[] {
    return this._alerts;
  }

  ngOnDestroy(): void {
    this._alive = false;
  }

  push(alert: Alert): void {
    this.alerts.unshift(alert);
    timer(alert.level === 'warning' ? 7000 : 4000).subscribe(() => this._hide$.next());
  }

  clear(index: number): void {
    this._alerts.splice(index, 1);
  }
}
