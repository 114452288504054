import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import {
  AgGridGetRowsResponseDto,
  SmartGridDonatelyService,
  TransactionDto,
  TransactionPurposeDto
} from '@donately/app-frontend-library';
import { ColumnType } from '../../models/ColumnType';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transactions-card',
  templateUrl: './transactions-card.component.html'
})
export class TransactionsCardComponent implements OnChanges {

  @Input() sponsorshipId: string;
  @Input() contactId: string;
  @Input() godchildId: string;
  @Input() campaignId: string;
  @Input() columns: ColumnType[] = [];

  transactions: TransactionDto[] = [];

  constructor(
    private _transactionService: SmartGridDonatelyService,
    private _router: Router
  ) {
  }

  private static mapRowToTransaction(transactionId: string, row: { [p: string]: unknown }): TransactionDto {
    return {
      id: transactionId,
      transactionPurposes: [TransactionsCardComponent.mapTransactionPurpose(row)],
      bankAccount: {
        id: row.BankAccountId as string,
        name: row.BankAccountName as string,
        currency: {
          name: row.CurrencyName as string
        }
      },
      info: row.Info as string,
      contact: {
        id: row.ContactId as string,
        firstname: row.Firstname as string,
        lastname: row.Lastname as string
      },
      foreignCurrency: { name: row.SourceCurrencyName as string },
      date: row.Date as string,
      isImportApproved: row.IsImportApproved as boolean
    };
  }

  private static mapTransactionPurpose(row: { [p: string]: unknown }): TransactionPurposeDto {
    return {
      campaign: { name: row.CampaignName as string },
      donationPurpose: { name: row.DonationPurposeName as string },
      godchild: { firstname: row.GodchildFirstname as string, lastname: row.GodchildLastname as string },
      amount: row.Amount as number,
      isAdditionalSponsorshipPayment: row.IsAdditionalSponsorshipPayment as boolean
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.sponsorshipId?.currentValue
      && !changes.contactId?.currentValue
      && !changes.godchildId?.currentValue
      && !changes.campaignId?.currentValue) {
      return;
    }


    this._transactionService.getTableData('Transaction', {
      filterModel: {
        [this.getPropertyName()]: {
          filterType: 'text',
          type: 'equals',
          filter: this.getFilter()
        }
      },
      sortModel: [
        {
          sort: 'desc',
          colId: 'Date'
        }
      ],
      endRow: 100
    }).pipe(take(1)).subscribe(result => this.mapGetRowsResponseToTransactions(result));
  }

  getPropertyName(): string {
    if (this.sponsorshipId) {
      return 'SponsorshipId';
    }

    if (this.contactId) {
      return 'ContactId';
    }

    if (this.godchildId) {
      return 'GodchildId';
    }

    if (this.campaignId) {
      return 'CampaignId';
    }
  }

  getFilter(): string {
    return this.sponsorshipId || this.contactId || this.godchildId || this.campaignId;
  }

  openTransaction(id: string): void {
    this._router.navigate(['transactions', id]);
  }

  private mapGetRowsResponseToTransactions(response: AgGridGetRowsResponseDto): void {
    const transactions = new Map<string, TransactionDto>();

    for (const row of response.rowData) {
      const transactionId = row.TransactionId as unknown as string;
      if (transactions.has(transactionId)) {
        const transaction = transactions.get(transactionId);
        transaction.transactionPurposes.push(TransactionsCardComponent.mapTransactionPurpose(row));
      } else {
        transactions.set(transactionId, TransactionsCardComponent.mapRowToTransaction(transactionId, row));
      }
    }

    this.transactions = Array.from(transactions.values());
  }
}
