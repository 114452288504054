<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    [formControl]="control"
    [matAutocomplete]="accountAutocomplete"
    matInput
    type="text">
  <mat-autocomplete #accountAutocomplete="matAutocomplete" [displayWith]="displayAccount" autoActiveFirstOption>
    <mat-option
      *ngFor="let account of accounts | async"
      [value]="account">{{ displayAccount(account) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
