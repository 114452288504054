import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NoteDonatelyService, NoteDto } from '@donately/app-frontend-library';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import 'quill-mention';
import { switchMap, take, takeWhile } from 'rxjs/operators';
import { AlertService } from '../../../core/alert/alert.service';
import { Subject } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit, OnDestroy {

  @ViewChild(QuillEditorComponent, { static: true }) quillRef: QuillEditorComponent;

  @Input() entityId: string;

  modules: QuillModules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: unknown, insertItem: (item: unknown) => void): void => {
        const editor = this.quillRef.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm: string, renderList: (values: unknown[], searchTerm: string) => void): void => {
        const values = [
          { id: 1, value: 'Fredrik Sundqvist' },
          { id: 2, value: 'Remy Marthaler' },
          { id: 3, value: 'Manuela Quero' },
          { id: 4, value: 'Patrik Sjölin' }
        ];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      }
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['link']
    ]
  };
  content = '';
  notes: NoteDto[] = [];
  editMode = true;
  reload$ = new Subject();

  private _alive = true;

  constructor(
    public authService: AuthService,
    private _noteService: NoteDonatelyService,
    private _alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.reload$.pipe(
      takeWhile(() => this._alive),
      switchMap(() => this._noteService.getNotes(this.entityId))
    ).subscribe(notes => {
      this.notes = notes;
    });

    this.reload$.next();
  }

  ngOnDestroy(): void {
    this._alive = false;
  }

  save(): void {
    this._noteService.createNote({
      contentHtml: this.content,
      contentDelta: JSON.stringify(this.quillRef.quillEditor.getContents()),
      entityId: this.entityId,
      entity: 'Sponsorship'
    }).pipe(take(1)).subscribe(() => {
      this.quillRef.quillEditor.setContents(null);
      this.reload$.next();
      this._alertService.push({
        level: 'success',
        title: 'Notiz erstellt',
        message: 'Notiz wurde erfoglreich erstellt!'
      });
    });
  }

  delete(id: string): void {
    this._noteService.deleteNote(id).pipe(take(1)).subscribe(() => {
      this.reload$.next();
      this._alertService.push({
        level: 'success',
        title: 'Notiz gelöscht',
        message: 'Notiz erfolgreich gelöscht!'
      });
    });
  }
}
