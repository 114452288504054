<app-dialog *transloco="let t; read: 'sponsorshipDetails'" class="w-96 block" title="Paten bearbeiten">
  <ng-container content>

    <form [formGroup]="form">

      <app-godchild-autocomplete [control]="form.get('godchild')" labelName="Patenkind"></app-godchild-autocomplete>

      <div class="flex flex-row gap-4">
        <mat-form-field class="w-full">
          <mat-label>Start</mat-label>
          <input [matDatepicker]="startPicker" formControlName="startDate" matInput>
          <mat-datepicker-toggle [for]="startPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Ende</mat-label>
          <input [matDatepicker]="endPicker" formControlName="endDate" matInput>
          <mat-datepicker-toggle [for]="endPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex flex-row gap-4">
        <mat-form-field class="w-3/4">
          <mat-label>Betrag</mat-label>
          <input
            autocomplete="off" formControlName="targetAmount"
            matInput>
        </mat-form-field>

        <app-currency-autocomplete
          [control]="form.get('currency')"
          [preselectFirstOption]="true"
          labelName="Währung"></app-currency-autocomplete>
      </div>

      <app-payment-cycle [control]="form.get('paymentCycle')" labelName="Zahlungszyklus"></app-payment-cycle>

    </form>

  </ng-container>
  <ng-container footer>

    <!-- Cancel -->
    <button [matDialogClose]="'cancelled'" mat-stroked-button>Abbrechen</button>

    <!-- Save button -->
    <button
      (click)="save()"
      [color]="'primary'"
      [disabled]="form.invalid"
      class="relative"
      mat-flat-button>
      Speichern
    </button>
  </ng-container>
</app-dialog>
