<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    [formControl]="control"
    [matAutocomplete]="campaignAutocomplete"
    matInput
    type="text">
  <mat-autocomplete #campaignAutocomplete="matAutocomplete" [displayWith]="display" autoActiveFirstOption>
    <mat-option
      *ngFor="let campaignOrSponsorship of campaigns | async"
      [value]="campaignOrSponsorship">{{ display(campaignOrSponsorship) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
