import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: unknown[], propertyName: string): unknown[] {
    if (array == null) {
      return [];
    }

    return array.sort(
      (a, b) => a[propertyName] > b[propertyName]
        ? 1
        : (b[propertyName] > a[propertyName] ? -1 : 0));
  }

}
