import { Pipe, PipeTransform } from '@angular/core';
import { transactionAmount, transactionCurrency } from '../../utils/transactios.utils';
import { TransactionDto } from '@donately/app-frontend-library';

@Pipe({
  name: 'transactionAmount'
})
export class TransactionAmountPipe implements PipeTransform {

  transform(transaction: TransactionDto): string {
    return `${transactionCurrency(transaction)} ${transactionAmount(transaction)}`;
  }

}
