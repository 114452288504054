<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    [formControl]="control"
    [matAutocomplete]="purposeAutocomplete"
    matInput
    type="text">
  <mat-autocomplete #purposeAutocomplete="matAutocomplete" [displayWith]="displayPurpose" autoActiveFirstOption>
    <mat-option
      *ngFor="let purpose of campaign?.donationPurposes"
      [value]="purpose">{{ displayPurpose(purpose) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
