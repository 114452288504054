import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomFieldDefinitionDto, CustomFieldDonatelyService, CustomFieldType } from '@donately/app-frontend-library';
import { EnumTranslatorService } from '../../../../core/transloco/enum-translator.service';

@Component({
  selector: 'app-add',
  templateUrl: './add-or-update.component.html'
})
export class AddOrUpdateComponent {
  form: FormGroup;
  customFieldType = CustomFieldType;
  isNewCustomField: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { group: string; customField: CustomFieldDefinitionDto | null },
    public enumTranslator: EnumTranslatorService,
    private _matDialogRef: MatDialogRef<AddOrUpdateComponent>,
    private _fb: FormBuilder,
    private _customFieldService: CustomFieldDonatelyService
  ) {
    this.isNewCustomField = this.data.customField == null;
    this.form = _fb.group({
      type: [this.data.customField?.type ?? CustomFieldType.Input, Validators.required],
      label: [this.data.customField?.label ?? '', Validators.required]
    });
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    const customField: CustomFieldDefinitionDto = {
      type: this.form.get('type').value,
      label: this.form.get('label').value,
      group: this.data.group
    };

    if (this.isNewCustomField) {
      this._customFieldService.createField(customField)
        .subscribe((createdField) => this._matDialogRef.close(createdField), () => this.form.enable());
    } else {
      this._customFieldService.updateCustomFieldDefinition(
        this.data.customField.id,
        { label: this.form.get('label').value }
      )
        .subscribe(
          () => this._matDialogRef.close({ ...this.data.customField, label: this.form.get('label').value }),
          () => this.form.enable()
        );
    }
  }

}
