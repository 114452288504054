<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && (auth.user$ | async)?.photoURL"
            [src]="(auth?.user$ | async)?.photoURL" alt="user account">
        <mat-icon
            *ngIf="!showAvatar || !(auth.user$ | async)?.photoURL"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !(auth.user$ | async)?.photoURL,
                          'bg-green-500': auth.userStatus === 'online',
                          'bg-amber-500': auth.userStatus === 'away',
                          'bg-red-500': auth.userStatus === 'busy',
                          'bg-gray-400': auth.userStatus === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <div *transloco="let t; read: 'user'">
        <button
            mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{t("signedInAs")}}</span>
            <span class="mt-1.5 text-md font-medium">{{(auth.user$ | async)?.displayName}}</span>
        </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{t('profile')}}</span>
        </button>
        <button mat-menu-item (click)="toggleLayoutDrawer()">
            <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            <span>{{t('layout')}}</span>
        </button>
        <button
            mat-menu-item
            [matMenuTriggerFor]="userStatus">
            <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
            <span>{{t('status.title')}}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button
            mat-menu-item
            (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            <span>{{t("logout")}}</span>
        </button>
    </div>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <div *transloco="let t; read: 'user'">
        <button
            mat-menu-item
            (click)="updateUserStatus('online')">
            <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
            <span>{{t('status.online')}}</span>
        </button>
        <button
            mat-menu-item
            (click)="updateUserStatus('away')">
            <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
            <span>{{t('status.away')}}</span>
        </button>
        <button
            mat-menu-item
            (click)="updateUserStatus('busy')">
            <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
            <span>{{t('status.busy')}}</span>
        </button>
        <button
            mat-menu-item
            (click)="updateUserStatus('not-visible')">
            <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
            <span>{{t('status.invisible')}}</span>
        </button>
    </div>
</mat-menu>
