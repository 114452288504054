import { Pipe, PipeTransform } from '@angular/core';
import { EnumTranslatorService } from '../../../core/transloco/enum-translator.service';
import { PaymentCycle } from '@donately/app-frontend-library';
import { Observable } from 'rxjs';

@Pipe({
  name: 'paymentCycle'
})
export class PaymentCyclePipe implements PipeTransform {

  constructor(private _enumTranslator: EnumTranslatorService) {
  }

  transform(value: PaymentCycle): Observable<string> {
    return this._enumTranslator.translate$('paymentCycle.' + value);
  }
}
