import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactDonatelyService, ContactDto } from '@donately/app-frontend-library';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-contact-autocomplete',
  templateUrl: './contact-autocomplete.component.html'
})
export class ContactAutocompleteComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() labelName: string;
  @Input() excludeIds: string[] = [];

  @Output() optionSelected = new EventEmitter<ContactDto>();

  @ViewChild('inputElement') inputElement: ElementRef;

  contacts: Observable<ContactDto[]>;

  constructor(
    private _contactsService: ContactDonatelyService
  ) {
  }

  ngOnInit(): void {
    this.contacts = this.control.valueChanges.pipe(
      filter(value => typeof value === 'string' || value instanceof String),
      startWith(''),
      switchMap(value => {
        return this._contactsService.getContacts({ filterValue: value });
      }),
      map((contacts: ContactDto[]) => contacts.filter(contact => this.excludeIds.indexOf(contact.id) === -1))
    );
  }

  displayContact(contact: ContactDto): string {
    if (contact?.firstname == null) {
      return;
    }

    return `${contact?.firstname} ${contact?.lastname}`;
  }

  focus(): void {
    this.inputElement.nativeElement.focus();
  }

}
