import { Component, Input } from '@angular/core';
import { ContactDto, SponsorshipDto } from '@donately/app-frontend-library';

@Component({
  selector: 'app-sponsorship-status',
  templateUrl: './sponsorship-status.component.html'
})
export class SponsorshipStatusComponent {

  @Input() sponsorship: ContactDto & SponsorshipDto;

}
