<svg viewBox="0 0 450.819 494.969" xmlns="http://www.w3.org/2000/svg">
  <g id="undraw_Taken_re_yn20" transform="translate(0 0.001)">
    <path
      d="M632.5,181.676c-31.251-4.987-66.839-7.648-103.9-7.648a683.665,683.665,0,0,0-95.844,6.422c.007-.449.013-.9.033-1.347a99.879,99.879,0,0,1,199.7,1.22C632.492,180.777,632.5,181.227,632.5,181.676Z"
      data-name="Pfad 837"
      fill="var(--fuse-tertiary)"
      id="Pfad_837"
      transform="translate(-319.455 -81.258)" />
    <g data-name="Gruppe 69" id="Gruppe_69" opacity="0.1" transform="translate(112.997 -0.001)">
      <path
        d="M545.577,81.7a99.911,99.911,0,0,0-113.24,96.011c-.02.449-.027.9-.033,1.347,8.689-1.247,17.687-2.315,26.924-3.207A99.894,99.894,0,0,1,545.577,81.7Z"
        data-name="Pfad 838"
        fill="#fff"
        id="Pfad_838"
        transform="translate(-432.303 -80.802)" />
    </g>
    <path
      d="M620.471,234.109a402.3,402.3,0,0,0-43.706-9.277,632.36,632.36,0,0,0-72.892-7c-10.169-.416-20.526-.63-31-.63-8.3,0-16.531.134-24.648.4a652.445,652.445,0,0,0-71.162,6.013,418.48,418.48,0,0,0-51.783,10.5c-39.69,10.973-61.55,25.68-61.55,41.406s21.859,30.433,61.55,41.406c27.913,7.722,62.327,12.984,99.826,15.4,15.478,1.006,31.479,1.515,47.768,1.515,16.343,0,32.4-.516,47.922-1.522,34.126-2.205,65.7-6.77,92.124-13.407.141-.04.275-.074.416-.107,1.783-.442,3.539-.9,5.276-1.367.623-.174,1.24-.342,1.857-.509a.652.652,0,0,1,.107-.034c.59-.161,1.173-.328,1.756-.489,1.167-.335,2.319-.664,3.452-1.005.054-.013.1-.027.148-.04.985-.295,1.951-.59,2.909-.885.784-.248,1.562-.489,2.333-.737q.845-.271,1.669-.543c.4-.134.8-.261,1.187-.4.53-.174,1.059-.355,1.582-.536.221-.074.443-.147.657-.228.5-.168.992-.342,1.481-.516.53-.188,1.059-.382,1.582-.57.436-.154.872-.315,1.3-.476.362-.141.717-.275,1.079-.409.985-.369,1.951-.751,2.9-1.126.476-.194.952-.382,1.421-.576s.932-.382,1.388-.577q1.126-.473,2.212-.945c.349-.161.7-.315,1.046-.462.262-.121.516-.235.778-.355.188-.08.375-.168.556-.255.677-.308,1.347-.623,2-.938q3.781-1.81,7.058-3.7c13.708-7.85,20.941-16.557,20.941-25.607C682.02,259.79,660.161,245.083,620.471,234.109Zm-.362,81.526c-27.946,7.729-62.455,12.991-100.054,15.384-15.29.972-31.1,1.475-47.178,1.475-16.028,0-31.78-.5-47.024-1.468-37.659-2.386-72.221-7.648-100.208-15.391-39.06-10.806-60.571-25.05-60.571-40.119a16.166,16.166,0,0,1,.5-3.948c3.418-13.594,24.447-26.317,60.075-36.171a418.152,418.152,0,0,1,51.388-10.437,654.261,654.261,0,0,1,71.591-6.033q11.986-.392,24.253-.389c10.33,0,20.546.208,30.587.61a631.035,631.035,0,0,1,73.314,7.038,401.441,401.441,0,0,1,43.33,9.21c35.621,9.854,56.643,22.57,60.068,36.158a15.968,15.968,0,0,1,.5,3.962C680.68,290.585,659.169,304.829,620.109,315.635Z"
      data-name="Pfad 839"
      fill="#3f3d56"
      id="Pfad_839"
      transform="translate(-263.734 -125.768)" />
    <path
      d="M682.11,302.265c0,8.956-7.381,17.429-20.559,25-2.192,1.26-4.551,2.494-7.058,3.7-.657.315-1.327.63-2,.938-.181.087-.369.174-.556.255-.262.121-.516.235-.778.355-.342.147-.7.3-1.046.462q-1.086.473-2.212.945c-.456.194-.925.389-1.388.576s-.945.382-1.421.576c-.952.375-1.917.757-2.9,1.126-.362.134-.718.268-1.079.409-.429.161-.865.322-1.3.476-.523.188-1.052.382-1.582.57-.489.174-.979.349-1.481.516-.214.08-.436.154-.657.228-.523.181-1.052.362-1.582.536-.389.134-.784.261-1.186.4q-.825.271-1.669.543c-.771.248-1.548.489-2.333.737-.958.295-1.924.59-2.909.885-.047.013-.094.027-.148.04-1.133.342-2.286.67-3.452,1.005-.583.161-1.166.328-1.756.489a.653.653,0,0,0-.107.033c-.617.168-1.234.335-1.857.509-1.729.456-3.492.918-5.276,1.367-.141.033-.275.067-.416.107-25.707,6.422-57.246,11.1-92.15,13.36-15.29.985-31.231,1.508-47.607,1.508-16.524,0-32.6-.53-48.009-1.535-92-6-160.464-28.844-160.464-56.113a16.809,16.809,0,0,1,.75-4.893c.04.114.081.221.128.335,9.565,25.124,77.45,45.428,165.531,50.382,13.494.764,27.47,1.16,41.775,1.16,14.365,0,28.4-.4,41.943-1.166,88.008-4.974,155.825-25.285,165.357-50.389q.242-.6.422-1.207a16.964,16.964,0,0,1,1.04,5.778Z"
      data-name="Pfad 840"
      fill="#3f3d56"
      id="Pfad_840"
      transform="translate(-264.206 -151.908)" />
    <path
      d="M344.086,287.607c-11.156,0-23.013-2.4-23.013-6.849s11.857-6.849,23.013-6.849,23.013,2.4,23.013,6.849S355.242,287.607,344.086,287.607Zm0-12.357c-12.772,0-21.672,2.9-21.672,5.508s8.9,5.508,21.672,5.508,21.672-2.9,21.672-5.508-8.9-5.508-21.672-5.508Z"
      data-name="Pfad 841"
      fill="#3f3d56"
      id="Pfad_841"
      transform="translate(-282.637 -144.465)" />
    <path
      d="M415.085,334.607c-11.156,0-23.013-2.4-23.013-6.849s11.857-6.849,23.013-6.849,23.013,2.4,23.013,6.849S426.242,334.607,415.085,334.607Zm0-12.357c-12.772,0-21.672,2.9-21.672,5.508s8.9,5.508,21.672,5.508,21.672-2.9,21.672-5.508-8.9-5.508-21.672-5.508Z"
      data-name="Pfad 842"
      fill="#3f3d56"
      id="Pfad_842"
      transform="translate(-306.044 -159.959)" />
    <path
      d="M784.085,287.607c-11.156,0-23.013-2.4-23.013-6.849s11.857-6.849,23.013-6.849,23.013,2.4,23.013,6.849S795.242,287.607,784.085,287.607Zm0-12.357c-12.772,0-21.672,2.9-21.672,5.508s8.9,5.508,21.672,5.508,21.672-2.9,21.672-5.508-8.9-5.508-21.672-5.508Z"
      data-name="Pfad 843"
      fill="#3f3d56"
      id="Pfad_843"
      transform="translate(-427.692 -144.465)" />
    <path
      d="M713.085,334.607c-11.156,0-23.013-2.4-23.013-6.849s11.857-6.849,23.013-6.849,23.013,2.4,23.013,6.849S724.242,334.607,713.085,334.607Zm0-12.357c-12.772,0-21.672,2.9-21.672,5.508s8.9,5.508,21.672,5.508,21.672-2.9,21.672-5.508-8.9-5.508-21.672-5.508Z"
      data-name="Pfad 844"
      fill="#3f3d56"
      id="Pfad_844"
      transform="translate(-404.285 -159.959)" />
    <path
      d="M564.085,356.607c-11.156,0-23.013-2.4-23.013-6.849s11.857-6.849,23.013-6.849,23.013,2.4,23.013,6.849S575.242,356.607,564.085,356.607Zm0-12.357c-12.772,0-21.672,2.9-21.672,5.508s8.9,5.508,21.672,5.508,21.672-2.9,21.672-5.508-8.9-5.508-21.672-5.508Z"
      data-name="Pfad 845"
      fill="#3f3d56"
      id="Pfad_845"
      transform="translate(-355.164 -167.212)" />
    <circle
      cx="28.162"
      cy="28.162"
      data-name="Ellipse 241"
      fill="#2f2e41"
      id="Ellipse_241"
      r="28.162"
      transform="translate(197.724 273.958)" />
    <rect
      data-name="Rechteck 464"
      fill="#2f2e41"
      height="8.556"
      id="Rechteck_464"
      transform="translate(201.471 327.49) rotate(-26.601)"
      width="15.329" />
    <ellipse
      cx="2.674"
      cy="7.13"
      data-name="Ellipse 242"
      fill="#2f2e41"
      id="Ellipse_242"
      rx="2.674"
      ry="7.13"
      transform="translate(193.731 328.742) rotate(-56.601)" />
    <rect
      data-name="Rechteck 465"
      fill="#2f2e41"
      height="15.329"
      id="Rechteck_465"
      transform="translate(232.764 328.27) rotate(-63.399)"
      width="8.556" />
    <ellipse
      cx="7.13"
      cy="2.674"
      data-name="Ellipse 243"
      fill="#2f2e41"
      id="Ellipse_243"
      rx="7.13"
      ry="2.674"
      transform="translate(243.191 332.13) rotate(-33.399)" />
    <circle
      cx="9.625"
      cy="9.625"
      data-name="Ellipse 244"
      fill="#fff"
      id="Ellipse_244"
      r="9.625"
      transform="translate(214.29 285.607)" />
    <ellipse
      cx="3.195"
      cy="3.218"
      data-name="Ellipse 245"
      fill="#3f3d56"
      id="Ellipse_245"
      rx="3.195"
      ry="3.218"
      transform="translate(219.449 291.095) rotate(-45)" />
    <path
      d="M613.351,476.092c.423-10.426-8.562-19.257-20.07-19.724s-21.18,7.606-21.6,18.032,7.576,12.795,19.084,13.262S612.927,486.518,613.351,476.092Z"
      data-name="Pfad 846"
      fill="var(--fuse-primary)"
      id="Pfad_846"
      transform="translate(-365.248 -204.609)" />
    <ellipse
      cx="4.42"
      cy="14.081"
      data-name="Ellipse 246"
      fill="#2f2e41"
      id="Ellipse_246"
      rx="4.42"
      ry="14.081"
      transform="translate(242.638 298.38) rotate(-40.645)" />
    <ellipse
      cx="14.081"
      cy="4.42"
      data-name="Ellipse 247"
      fill="#2f2e41"
      id="Ellipse_247"
      rx="14.081"
      ry="4.42"
      transform="translate(184.162 313.986) rotate(-49.355)" />
    <path
      d="M606,546.419a6.417,6.417,0,0,1-12.626,2.3l0-.012c-.631-3.487,2.065-4.721,5.552-5.352S605.364,542.932,606,546.419Z"
      data-name="Pfad 847"
      fill="#fff"
      id="Pfad_847"
      transform="translate(-372.374 -233.2)" />
    <path
      d="M529.075,535.977a1.341,1.341,0,0,1-1.341-1.341v-79.1a1.341,1.341,0,0,1,2.681,0v79.1a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 848"
      fill="#ccc"
      id="Pfad_848"
      transform="translate(-350.767 -203.9)" />
    <path
      d="M499.075,652.527a1.341,1.341,0,0,1-1.341-1.341V593.538a1.341,1.341,0,1,1,2.681,0v57.648a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 849"
      fill="#ccc"
      id="Pfad_849"
      transform="translate(-340.877 -249.394)" />
    <path
      d="M576.075,570.977a1.341,1.341,0,0,1-1.341-1.341v-79.1a1.341,1.341,0,1,1,2.681,0v79.1a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 850"
      fill="#ccc"
      id="Pfad_850"
      transform="translate(-366.262 -215.438)" />
    <path
      d="M547.075,679.054a1.341,1.341,0,0,1-1.341-1.341V645.538a1.341,1.341,0,1,1,2.681,0v32.176a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 851"
      fill="#ccc"
      id="Pfad_851"
      transform="translate(-356.701 -266.537)" />
    <path
      d="M588.075,433.054a1.341,1.341,0,0,1-1.341-1.341V399.538a1.341,1.341,0,0,1,2.681,0v32.176a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 852"
      fill="#ccc"
      id="Pfad_852"
      transform="translate(-370.218 -185.438)" />
    <path
      d="M488.075,454.054a1.341,1.341,0,0,1-1.341-1.341V420.538a1.341,1.341,0,0,1,2.681,0v32.176a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 853"
      fill="#ccc"
      id="Pfad_853"
      transform="translate(-337.251 -192.361)" />
    <path
      d="M662.075,459.054a1.341,1.341,0,0,1-1.341-1.341V425.538a1.341,1.341,0,1,1,2.681,0v32.176a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 854"
      fill="#ccc"
      id="Pfad_854"
      transform="translate(-394.613 -194.01)" />
    <path
      d="M658.075,605.758a1.341,1.341,0,0,1-1.341-1.341V565.538a1.341,1.341,0,1,1,2.681,0v38.879A1.341,1.341,0,0,1,658.075,605.758Z"
      data-name="Pfad 855"
      fill="#ccc"
      id="Pfad_855"
      transform="translate(-393.294 -240.164)" />
    <path
      d="M618.075,647.527a1.341,1.341,0,0,1-1.341-1.341V588.538a1.341,1.341,0,1,1,2.681,0v57.648a1.341,1.341,0,0,1-1.341,1.341Z"
      data-name="Pfad 856"
      fill="#ccc"
      id="Pfad_856"
      transform="translate(-380.107 -247.746)" />
    <path
      d="M626.075,489.977a1.341,1.341,0,0,1-1.341-1.341v-79.1a1.341,1.341,0,1,1,2.681,0v79.1A1.341,1.341,0,0,1,626.075,489.977Z"
      data-name="Pfad 857"
      fill="#ccc"
      id="Pfad_857"
      transform="translate(-382.745 -188.735)" />
    <ellipse
      cx="4.531"
      cy="14.435"
      data-name="Ellipse 248"
      fill="#2f2e41"
      id="Ellipse_248"
      rx="4.531"
      ry="14.435"
      transform="translate(385.684 450.338) rotate(-39.938)" />
    <circle
      cx="28.869"
      cy="28.869"
      data-name="Ellipse 249"
      fill="#2f2e41"
      id="Ellipse_249"
      r="28.869"
      transform="matrix(0.316, -0.949, 0.949, 0.316, 331.2, 471.741)" />
    <rect
      data-name="Rechteck 466"
      fill="#2f2e41"
      height="15.714"
      id="Rechteck_466"
      transform="translate(371.167 476.137)"
      width="8.77" />
    <rect
      data-name="Rechteck 467"
      fill="#2f2e41"
      height="15.714"
      id="Rechteck_467"
      transform="translate(353.626 476.137)"
      width="8.77" />
    <ellipse
      cx="7.309"
      cy="2.741"
      data-name="Ellipse 250"
      fill="#2f2e41"
      id="Ellipse_250"
      rx="7.309"
      ry="2.741"
      transform="translate(365.32 489.292)" />
    <ellipse
      cx="7.309"
      cy="2.741"
      data-name="Ellipse 251"
      fill="#2f2e41"
      id="Ellipse_251"
      rx="7.309"
      ry="2.741"
      transform="translate(347.779 488.927)" />
    <path
      d="M798.573,696.115c2.578-10.381,13.957-16.491,25.415-13.645s18.658,13.568,16.08,23.949-11.13,10.415-22.589,7.57S795.995,706.5,798.573,696.115Z"
      data-name="Pfad 858"
      fill="#ccc"
      id="Pfad_858"
      transform="translate(-439.92 -278.924)" />
    <ellipse
      cx="4.531"
      cy="14.435"
      data-name="Ellipse 252"
      fill="#2f2e41"
      id="Ellipse_252"
      rx="4.531"
      ry="14.435"
      transform="translate(320.219 437.993) rotate(-64.626)" />
    <circle
      cx="9.625"
      cy="9.625"
      data-name="Ellipse 253"
      fill="#fff"
      id="Ellipse_253"
      r="9.625"
      transform="translate(353.777 437.763)" />
    <circle
      cx="3.208"
      cy="3.208"
      data-name="Ellipse 254"
      fill="#3f3d56"
      id="Ellipse_254"
      r="3.208"
      transform="translate(356.238 440.73)" />
    <circle
      cx="4.022"
      cy="4.022"
      data-name="Ellipse 255"
      fill="#fff"
      id="Ellipse_255"
      r="4.022"
      transform="translate(359.297 463.462)" />
    <path
      d="M857.134,818.538h-158.2a.67.67,0,0,1,0-1.341h158.2a.67.67,0,1,1,0,1.341Z"
      data-name="Pfad 859"
      fill="#3f3d56"
      id="Pfad_859"
      transform="translate(-406.986 -323.57)" />
  </g>
</svg>
