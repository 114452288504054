export const environment = {
    production: false,
    apiUrl: 'https://api.dev.donately.ch/api/v1',
    firebaseConfig: {
        apiKey: 'AIzaSyCWVdLA48T4IKtFgYh1Do0DAPCP8yqu6eg',
        authDomain: 'donately-dev.firebaseapp.com',
        databaseURL: 'https://donately-dev.firebaseio.com',
        projectId: 'donately-dev',
        storageBucket: 'donately-dev.appspot.com',
        messagingSenderId: '4630947263',
        appId: '1:4630947263:web:209a23a57ffffc361a6d0a',
        measurementId: 'G-M5NFV2VDZE',
    },
    google: {
        mapsApiKey: 'AIzaSyAYsVh0jBulepw5OPZNHPZp2KdKMHiPfpk',
    },
};
