import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '@donately/app-frontend-library';
import { EnumTranslatorService } from '../../../core/transloco/enum-translator.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  constructor(private _enumTranslator: EnumTranslatorService) {
  }

  transform(value: Gender): Observable<string> {
    return this._enumTranslator.translate$('gender.' + value);
  }

}
