import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { AuthDonatelyService, RegisterUserDto, UserWithOrganisationsDto } from '@donately/app-frontend-library';


@Injectable()
export class AuthService implements OnDestroy {
  user$: Observable<firebase.User>;
  userStatus = 'online';

  private _authenticated: boolean = false;
  private _alive = true;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _auth: AngularFireAuth,
    private _authDonatelyService: AuthDonatelyService
  ) {
    this.user$ = _auth.user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  ngOnDestroy(): void {
    this._alive = false;
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<unknown> {
    return this._authDonatelyService.forgotPassword({ email });
  }

  /**
   * Reset password
   *
   * @param password
   * @param token
   */
  resetPassword(password: string, token: string): Observable<unknown> {
    return this._authDonatelyService.resetPassword({ password, token });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string; rememberMe: boolean }): Promise<unknown> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return Promise.reject('User is already logged in.');
    }

    return this._auth.setPersistence(credentials.rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION
    )
      .then(() => this._auth
        .signInWithEmailAndPassword(
          credentials.email,
          credentials.password
        ));
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<boolean> {
    return of(true);
  }

  /**
   * Sign out
   */
  signOut(): Observable<unknown> {
    return of(this._auth.signOut());
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: RegisterUserDto): Observable<UserWithOrganisationsDto> {
    return this._authDonatelyService.register(user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<unknown> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {

    // return of(this._auth.user != null);

    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
