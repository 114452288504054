import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { CurrencyDonatelyService, OrganisationDonatelyService, OrganisationDto } from '@donately/app-frontend-library';
import { AuthActionTypes, SetOrganisationsSuccess } from '../actions/auth.actions';
import {
  ActivateCurrency,
  ActivateCurrencySuccess,
  DisableCurrencySuccess,
  OrganisationActionTypes,
  UpdateOrganisation,
  UpdateOrganisationSuccess
} from '../actions/organisation.actions';
import { AlertService } from '../../alert/alert.service';


@Injectable()
export class CoreEffects {

  updateOrganisationsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.SET_ORGANISATIONS),
      concatMap(() => {
        return this._organisationService.getOrganisations()
          .pipe(map(organisations => new SetOrganisationsSuccess(organisations)));
      })
    );
  });

  activateCurrencyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActionTypes.ACTIVATE_CURRENCY),
      concatMap((action: ActivateCurrency) => {
        return this._currencyService.activateCurrency(action.currency)
          .pipe(map((organisation: OrganisationDto) => {
                this._alertService.push({
                  level: 'success',
                  title: `${action.currency.name} aktiviert`,
                  message: `${action.currency.name} wurde als Währung hinzugefügt.`
                });
                return new ActivateCurrencySuccess({
                  id: organisation.id,
                  changes: organisation
                });
              }
            )
          );
      })
    );
  });

  disableCurrencyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActionTypes.DISABLE_CURRENCY),
      concatMap((action: ActivateCurrency) => {
        return this._currencyService.disableCurrency(action.currency.id)
          .pipe(map((organisation: OrganisationDto) => {
                this._alertService.push({
                  level: 'basic',
                  title: `${action.currency.name} deaktiviert`,
                  message: `${action.currency.name} wurde als Währung entfernt.`
                });
                return new DisableCurrencySuccess({
                  id: organisation.id,
                  changes: organisation
                });
              }
            )
          );
      })
    );
  });

  updateBankAccountEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActionTypes.UPDATE_ORGANISATION),
      concatMap((action: UpdateOrganisation) => {
        return this._organisationService.updateOrganisation(action.organisation)
          .pipe(map((organisation: OrganisationDto) => {
                this._alertService.push({
                  level: 'success',
                  title: 'Organisation aktualisiert',
                  message: 'Organisation wurde erfolgreich aktualisiert'
                });
                return new UpdateOrganisationSuccess({
                  id: organisation.id,
                  changes: organisation
                });
              }
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private _organisationService: OrganisationDonatelyService,
    private _currencyService: CurrencyDonatelyService,
    private _alertService: AlertService
  ) {
  }

}
