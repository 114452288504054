import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentOrganisationId } from '../../store/reducers/core.reducer';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class OrganisationInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.store.select(selectCurrentOrganisationId).pipe(
      take(1),
      switchMap((organisationId: string) => {
        if (!!organisationId) {
          request = request.clone({
            setHeaders: { 'Current-Organisation-Id': organisationId }
          });
        }
        return next.handle(request);
      })
    );
  }
}
