import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SponsorshipDto } from '@donately/app-frontend-library';
import { MatDialog } from '@angular/material/dialog';
import { EditSponsorshipDialogComponent } from './edit-sponsorship-dialog/edit-sponsorship-dialog.component';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sponsorship-card',
  templateUrl: './sponsorship-card.component.html'
})
export class SponsorshipCardComponent {

  @Input() sponsorship: SponsorshipDto;
  @Input() isEditable = true;
  @Input() displayMode: 'Godchild' | 'Contact' = 'Godchild';

  @Output() update = new EventEmitter<SponsorshipDto>();

  constructor(
    private _dialog: MatDialog,
    private _router: Router
  ) {
  }

  get displayName(): string {
    if (this.displayMode === 'Godchild') {
      return `${this.sponsorship.godchild.firstname} ${this.sponsorship.godchild.lastname}`;
    }

    let contactsList = `${this.sponsorship.contacts[0].firstname} ${this.sponsorship.contacts[0].lastname}`;

    if (this.sponsorship.contacts.length > 1) {
      contactsList += ` +${this.sponsorship.contacts.length - 1}`;
    }

    return contactsList;
  }

  edit(): void {
    this._dialog.open(EditSponsorshipDialogComponent, { data: this.sponsorship })
      .afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result !== 'cancelled') {
        this.update.emit(result);
      }
    });
  }

  navigate(): void {
    // noinspection JSIgnoredPromiseFromCall
    this._router.navigate(['sponsorships', this.sponsorship.id]);
  }

}
