/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';
import { OrganisationDonatelyService } from '@donately/app-frontend-library';
import { Store } from '@ngrx/store';
import { SetOrganisationsSuccess } from '../../store/actions/auth.actions';
import { selectOrganisationTotal } from '../../store/reducers/core.reducer';

@Injectable({
  providedIn: 'root'
})
export class HasOrganisationsGuard implements CanActivate, CanActivateChild {
  constructor(
    private _organisationService: OrganisationDonatelyService,
    private _router: Router,
    private _store: Store
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkHasOrganisations();
  }


  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkHasOrganisations();
  }

  private checkHasOrganisations(): Observable<boolean> {
    return this._store.select(selectOrganisationTotal).pipe(switchMap(orgTotal => {
      if (orgTotal > 0) {
        return of(true);
      }

      return this._organisationService.getOrganisations().pipe(
        retry(5),
        map((result) => {
          if (result.length === 0) {
            this._router.navigateByUrl('/onboarding');
            return false;
          } else {
            this._store.dispatch(new SetOrganisationsSuccess(result));
            return true;
          }
        })
      );
    }));
  }
}
