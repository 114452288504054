import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './store/effects/core.effects';
import { AlertComponent } from './alert/alert.component';
import { FuseAlertModule } from '../../@fuse/components/alert';
import { CommonModule } from '@angular/common';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,

    AuthModule,
    IconsModule,
    TranslocoCoreModule,

    EffectsModule.forRoot([CoreEffects]),
    StoreModule.forRoot(reducers, { metaReducers }),
    FuseAlertModule
  ],
  exports: [
    AlertComponent
  ],
  declarations: [
    AlertComponent
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'core/enums', alias: 'enums' },
      multi: true
    }
  ]
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
