import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-transaction-import-status',
  templateUrl: './transaction-import-status.component.html'
})
export class TransactionImportStatusComponent {

  @Input() isImportApproved: boolean;

}
