import { Pipe, PipeTransform } from '@angular/core';
import { EnumTranslatorService } from '../../../core/transloco/enum-translator.service';
import { SponsorshipStatus } from '@donately/app-frontend-library';
import { Observable } from 'rxjs';

@Pipe({
  name: 'sponsorshipStatus'
})
export class SponsorshipStatusPipe implements PipeTransform {

  constructor(private _enumTranslator: EnumTranslatorService) {
  }

  transform(value: SponsorshipStatus): Observable<string> {
    return this._enumTranslator.translate$('sponsorshipStatus.' + value);
  }

}
