<svg viewBox="0 0 584.401 289.751" xmlns="http://www.w3.org/2000/svg">
  <g id="undraw_Appreciation_re_p6rl" transform="translate(0 0)">
    <ellipse
      cx="13.619"
      cy="4.276"
      data-name="Ellipse 285"
      fill="#2f2e41"
      id="Ellipse_285"
      rx="13.619"
      ry="4.276"
      transform="matrix(0.357, -0.934, 0.934, 0.357, 187.321, 241.276)" />
    <circle
      cx="27.239"
      cy="27.239"
      data-name="Ellipse 286"
      fill="#2f2e41"
      id="Ellipse_286"
      r="27.239"
      transform="translate(139.752 273.171) rotate(-80.783)" />
    <rect
      data-name="Rechteck 572"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_572"
      transform="translate(158.589 272.025)"
      width="8.275" />
    <rect
      data-name="Rechteck 573"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_573"
      transform="translate(175.139 272.025)"
      width="8.275" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 287"
      fill="#2f2e41"
      id="Ellipse_287"
      rx="6.896"
      ry="2.586"
      transform="translate(158.589 284.438)" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 288"
      fill="#2f2e41"
      id="Ellipse_288"
      rx="6.896"
      ry="2.586"
      transform="translate(175.139 284.093)" />
    <circle
      cx="9.309"
      cy="9.309"
      data-name="Ellipse 289"
      fill="#fff"
      id="Ellipse_289"
      r="9.309"
      transform="matrix(1, -0.029, 0.029, 1, 162.111, 234.721)" />
    <circle
      cx="3.103"
      cy="3.103"
      data-name="Ellipse 290"
      fill="#3f3d56"
      id="Ellipse_290"
      r="3.103"
      transform="translate(168.588 240.649)" />
    <path
      d="M366.361,563.954c-2.2-9.85,4.831-19.8,15.7-22.231s21.469,3.589,23.668,13.439-5.006,13.483-15.878,15.91S368.56,573.8,366.361,563.954Z"
      data-name="Pfad 904"
      fill="#e6e6e6"
      id="Pfad_904"
      transform="translate(-221.782 -338.638)" />
    <ellipse
      cx="4.276"
      cy="13.619"
      data-name="Ellipse 291"
      fill="#2f2e41"
      id="Ellipse_291"
      rx="4.276"
      ry="13.619"
      transform="translate(126.185 236.036) rotate(-64.626)" />
    <path
      d="M387.217,630.853c0,2.666,6.864,7.93,14.481,7.93s14.759-7.506,14.759-10.171-7.142.517-14.759.517S387.217,628.187,387.217,630.853Z"
      data-name="Pfad 905"
      fill="#fff"
      id="Pfad_905"
      transform="translate(-229.595 -370.378)" />
    <path
      d="M576.207,426.451l-29.587,29.2-66.865,65.979-1.353,1.334-7.539,7.438-15.805-15.616-1.392-1.372-28.9-28.543h-.006L415.5,475.72l-5.471-5.408-16.293-16.09-2.2-2.176-25.969-25.653a74.412,74.412,0,0,1-12.978-17.475c-.316-.576-.614-1.157-.9-1.739a74.32,74.32,0,0,1,78.875-106.59h.007c.24.038.474.076.715.126a71.848,71.848,0,0,1,7.533,1.752,74.056,74.056,0,0,1,18.417,8.178q.9.56,1.784,1.138a74.741,74.741,0,0,1,11.681,9.544l.221-.221.221.221a74.969,74.969,0,0,1,6.85-6.059c.525-.411,1.056-.816,1.587-1.208a74.283,74.283,0,0,1,57.245-13.32,70.782,70.782,0,0,1,7.539,1.758q1.243.351,2.467.759c.658.215,1.316.436,1.967.677a73.641,73.641,0,0,1,15.426,7.653q1.584,1.034,3.124,2.163a74.345,74.345,0,0,1,8.861,112.7Z"
      data-name="Pfad 906"
      fill="var(--fuse-tertiary)"
      id="Pfad_906"
      transform="translate(-213.637 -249.825)" />
    <path
      d="M593.671,546.35l-66.865,65.979-1.353,1.335-7.539,7.438-15.805-15.616-1.392-1.372-28.9-28.543c4.643-1.081,11.777.4,14.389,1.7,6.192,3.055,11.283,8.07,17.57,10.929a29.181,29.181,0,0,0,17.918,1.98c11.271-2.365,19.992-11.081,27.683-19.657s15.552-17.791,26.361-21.77C581.44,546.648,587.562,546.173,593.671,546.35Z"
      data-name="Pfad 907"
      id="Pfad_907"
      opacity="0.15"
      transform="translate(-260.689 -340.523)" />
    <path
      d="M605.36,323.13a74.731,74.731,0,0,0-33.223-19.322c-.835-.234-1.663-.443-2.5-.639a73.9,73.9,0,0,0-11-1.765c-.645-.063-1.29-.108-1.942-.139a72.835,72.835,0,0,0-9.759.038A74.883,74.883,0,0,0,499.6,322.46l-.228-.228-.228.228a75.019,75.019,0,0,0-29.536-17.76c-.626-.2-1.259-.4-1.891-.582a75.689,75.689,0,0,0-26.323-2.814c-.246.013-.493.032-.733.057a74.825,74.825,0,0,0-43.893,18.595c-1.151,1.012-2.283,2.075-3.384,3.175A75.354,75.354,0,0,0,376.533,403.8c.221.595.462,1.183.715,1.771a74.727,74.727,0,0,0,16.134,24L418,453.874l1.935,1.916L473.2,508.368l1.372,1.36,13.99,13.813,10.809,10.67L605.36,429.568a75.346,75.346,0,0,0,0-106.438Zm-1.334,105.091L499.371,531.541l-9.455-9.335L476.223,508.69l-1.372-1.354-52.229-51.565-1.9-1.878-25.995-25.666A72.858,72.858,0,0,1,379.063,405c-.253-.588-.493-1.176-.715-1.765a73.451,73.451,0,0,1,16.374-78.768q1.765-1.765,3.611-3.377h.006a72.975,72.975,0,0,1,42.047-17.81q3.1-.266,6.211-.266c.778,0,1.55.013,2.328.038a73.428,73.428,0,0,1,18.753,3.036c.626.183,1.252.379,1.872.588a72.2,72.2,0,0,1,18.55,9.164,73.124,73.124,0,0,1,10.379,8.627l.67.67.221-.221.221.221.67-.67a73.16,73.16,0,0,1,54.209-21.415c.8.025,1.606.063,2.41.12.645.038,1.29.082,1.929.145a72.822,72.822,0,0,1,12.074,2.119c.847.215,1.689.449,2.523.708a73.341,73.341,0,0,1,30.618,122.073Z"
      data-name="Pfad 908"
      fill="#3f3d56"
      id="Pfad_908"
      transform="translate(-223.766 -250.406)" />
    <path
      d="M449.005,305.336c-4.06,3.207-8.418,6.167-11.056,10.55-1.942,3.238-2.783,7-3.409,10.72-1.214,7.235-1.733,14.648-4.111,21.586s-7,13.566-13.864,16.153c-4.256,1.6-8.949,1.512-13.484,1.2-11.188-.759-22.453-2.764-32.521-7.691-5.629-2.758-11.087-6.477-17.342-6.888a16.066,16.066,0,0,0-3.807.209,74.311,74.311,0,0,1,83.214-50.591h.006c.24.038.474.076.715.127a71.836,71.836,0,0,1,7.533,1.752A74.294,74.294,0,0,1,449.005,305.336Z"
      data-name="Pfad 909"
      id="Pfad_909"
      opacity="0.15"
      transform="translate(-215.7 -249.821)" />
    <path
      d="M223.649,597.922a18.183,18.183,0,0,0,17.558-3.093c6.15-5.162,8.078-13.665,9.647-21.539l4.64-23.29-9.715,6.689c-6.986,4.811-14.13,9.775-18.968,16.744s-6.949,16.48-3.062,24.019Z"
      data-name="Pfad 910"
      fill="#e6e6e6"
      id="Pfad_910"
      transform="translate(-168.774 -341.877)" />
    <path
      d="M225.187,643.313c-.983-7.162-1.994-14.417-1.3-21.656a34.059,34.059,0,0,1,6.567-17.857,29.707,29.707,0,0,1,7.621-6.906c.762-.481,1.464.727.7,1.206a28.241,28.241,0,0,0-11.171,13.478c-2.432,6.186-2.823,12.929-2.4,19.5.253,3.974.791,7.922,1.332,11.864a.724.724,0,0,1-.488.859.7.7,0,0,1-.859-.488Z"
      data-name="Pfad 911"
      fill="#f2f2f2"
      id="Pfad_911"
      transform="translate(-169.481 -359.071)" />
    <path
      d="M241.052,641.893a13.384,13.384,0,0,0,11.662,6.019c5.9-.28,10.825-4.4,15.256-8.312l13.1-11.57-8.673-.415c-6.237-.3-12.635-.577-18.576,1.347s-11.419,6.552-12.506,12.7Z"
      data-name="Pfad 912"
      fill="#e6e6e6"
      id="Pfad_912"
      transform="translate(-175.875 -370.325)" />
    <path
      d="M221.63,664.3c4.733-8.374,10.222-17.681,20.031-20.656a22.351,22.351,0,0,1,8.425-.87c.895.077.671,1.456-.222,1.379a20.766,20.766,0,0,0-13.446,3.557,34.946,34.946,0,0,0-9.241,9.973c-1.53,2.33-2.9,4.76-4.272,7.186C222.467,665.647,221.186,665.088,221.63,664.3Z"
      data-name="Pfad 913"
      fill="#f2f2f2"
      id="Pfad_913"
      transform="translate(-168.703 -375.944)" />
    <circle
      cx="27.239"
      cy="27.239"
      data-name="Ellipse 292"
      fill="#2f2e41"
      id="Ellipse_292"
      r="27.239"
      transform="translate(162.56 20.861)" />
    <rect
      data-name="Rechteck 574"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_574"
      transform="translate(177.385 69.477)"
      width="8.275" />
    <rect
      data-name="Rechteck 575"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_575"
      transform="translate(193.935 69.477)"
      width="8.275" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 293"
      fill="#2f2e41"
      id="Ellipse_293"
      rx="6.896"
      ry="2.586"
      transform="translate(177.385 81.89)" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 294"
      fill="#2f2e41"
      id="Ellipse_294"
      rx="6.896"
      ry="2.586"
      transform="translate(193.936 81.545)" />
    <circle
      cx="9.309"
      cy="9.309"
      data-name="Ellipse 295"
      fill="#fff"
      id="Ellipse_295"
      r="9.309"
      transform="translate(181.178 31.895)" />
    <ellipse
      cx="3.09"
      cy="3.112"
      data-name="Ellipse 296"
      fill="#3f3d56"
      id="Ellipse_296"
      rx="3.09"
      ry="3.112"
      transform="translate(189.266 43.085) rotate(-44.987)" />
    <path
      d="M396.08,243.705c-2.2-9.85,4.831-19.8,15.7-22.231s21.469,3.589,23.668,13.439-5.006,13.483-15.878,15.91S398.279,253.555,396.08,243.705Z"
      data-name="Pfad 914"
      fill="#e6e6e6"
      id="Pfad_914"
      transform="translate(-232.704 -220.936)" />
    <ellipse
      cx="4.276"
      cy="13.619"
      data-name="Ellipse 297"
      fill="#2f2e41"
      id="Ellipse_297"
      rx="4.276"
      ry="13.619"
      transform="translate(388.205 215.398) rotate(-39.512)" />
    <circle
      cx="27.239"
      cy="27.239"
      data-name="Ellipse 298"
      fill="#2f2e41"
      id="Ellipse_298"
      r="27.239"
      transform="translate(392.632 227.849) rotate(-9.217)" />
    <rect
      data-name="Rechteck 576"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_576"
      transform="translate(428.019 271.747)"
      width="8.275" />
    <rect
      data-name="Rechteck 577"
      fill="#2f2e41"
      height="14.826"
      id="Rechteck_577"
      transform="translate(411.469 271.747)"
      width="8.275" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 299"
      fill="#2f2e41"
      id="Ellipse_299"
      rx="6.896"
      ry="2.586"
      transform="translate(422.503 284.16)" />
    <ellipse
      cx="6.896"
      cy="2.586"
      data-name="Ellipse 300"
      fill="#2f2e41"
      id="Ellipse_300"
      rx="6.896"
      ry="2.586"
      transform="translate(405.952 283.815)" />
    <path
      d="M817.525,577.78c-8.587-7.1-13.36-13.6-6.932-21.382s18.6-8.335,27.189-1.239,10.337,19.155,3.909,26.935S826.112,584.876,817.525,577.78Z"
      data-name="Pfad 915"
      fill="var(--fuse-primary)"
      id="Pfad_915"
      transform="translate(-384.106 -341.943)" />
    <circle
      cx="9.309"
      cy="9.309"
      data-name="Ellipse 301"
      fill="#fff"
      id="Ellipse_301"
      r="9.309"
      transform="matrix(1, -0.029, 0.029, 1, 404.884, 227.206)" />
    <circle
      cx="3.103"
      cy="3.103"
      data-name="Ellipse 302"
      fill="#3f3d56"
      id="Ellipse_302"
      r="3.103"
      transform="translate(408.089 230.827)" />
    <path
      d="M769.869,606.234c-1.885,1.885-3.218,8-.566,10.649s10.445-.169,12.33-2.055-2.852-2.12-5.5-4.772S771.753,604.348,769.869,606.234Z"
      data-name="Pfad 916"
      fill="#fff"
      id="Pfad_916"
      transform="translate(-369.476 -362.33)" />
    <ellipse
      cx="4.276"
      cy="13.619"
      data-name="Ellipse 303"
      fill="#2f2e41"
      id="Ellipse_303"
      rx="4.276"
      ry="13.619"
      transform="matrix(0.934, -0.357, 0.357, 0.934, 436.017, 262.876)" />
    <path
      d="M721.769,678.329H138.632a.632.632,0,1,1,0-1.265H721.769a.632.632,0,1,1,0,1.265Z"
      data-name="Pfad 917"
      fill="#ccc"
      id="Pfad_917"
      transform="translate(-138 -388.577)" />
  </g>
</svg>
