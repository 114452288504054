<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    #input
    (focus)="input.select()"
    [formControl]="control"
    [matAutocomplete]="auto"
    matInput
    type="text">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBankAccount" autoActiveFirstOption>
    <mat-option
      *ngFor="let bankAccount of bankAccounts | async"
      [value]="bankAccount">{{ bankAccount.name }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
