import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnumTranslatorService implements OnDestroy {

  private enums: { [enumName: string]: string };
  private enumTranslations$: Observable<{ [enumName: string]: string }>;
  private _alive = true;

  constructor(
    private _translocoService: TranslocoService
  ) {
    this.enumTranslations$ = this._translocoService.langChanges$.pipe(
      takeWhile(() => this._alive),
      switchMap((lang) => this._translocoService.selectTranslation('core/enums/' + lang))
    );

    this.enumTranslations$.pipe(takeWhile(() => this._alive)).subscribe(translation => this.enums = translation);
  }

  translate(key: string): string {
    return this.enums?.[key];
  }

  translate$(key: string): Observable<string> {
    return this.enumTranslations$.pipe(map(enums => enums[key]));
  }

  ngOnDestroy(): void {
    this._alive = false;
  }
}
