import { Action } from '@ngrx/store';
import { CurrencyDto, OrganisationDto } from '@donately/app-frontend-library';
import { Update } from '@ngrx/entity';
import { Alert } from '../../alert/alert';

export enum OrganisationActionTypes {
  ACTIVATE_CURRENCY = '[CORE] Activate currency',
  ACTIVATE_CURRENCY_SUCCESS = '[CORE] Activate currency success',
  DISABLE_CURRENCY = '[CORE] Disable currency',
  DISABLE_CURRENCY_SUCCESS = '[CORE] Disable currency success',

  UPDATE_ORGANISATION = '[CORE] update organisation',
  UPDATE_ORGANISATION_SUCCESS = '[CORE] update organisation success',
}

export class ActivateCurrency implements Action {
  readonly type = OrganisationActionTypes.ACTIVATE_CURRENCY;

  constructor(public currency: CurrencyDto) {
  }
}

export class DisableCurrency implements Action {
  readonly type = OrganisationActionTypes.DISABLE_CURRENCY;

  constructor(public currency: CurrencyDto) {
  }
}

export class ActivateCurrencySuccess implements Action {
  readonly type = OrganisationActionTypes.ACTIVATE_CURRENCY_SUCCESS;

  constructor(public organisation: Update<OrganisationDto>) {
  }
}

export class DisableCurrencySuccess implements Action {
  readonly type = OrganisationActionTypes.DISABLE_CURRENCY_SUCCESS;

  constructor(public organisation: Update<OrganisationDto>) {
  }
}

export class UpdateOrganisation implements Action {
  readonly type = OrganisationActionTypes.UPDATE_ORGANISATION;

  constructor(public organisation: OrganisationDto, public alert: Alert) {
  }
}

export class UpdateOrganisationSuccess implements Action {
  readonly type = OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS;

  constructor(public organisation: Update<OrganisationDto>) {
  }
}

export type OrganisationActions =
  ActivateCurrency
  | ActivateCurrencySuccess
  | DisableCurrency
  | DisableCurrencySuccess;
