import { Pipe, PipeTransform } from '@angular/core';
import relativeDateFactory from 'tiny-relative-date/lib/factory';
import deTranslations from 'tiny-relative-date/translations/de';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  transform(value: string | Date): unknown {
    const relativeDate = relativeDateFactory(deTranslations);
    return relativeDate(value);
  }

}
