import { TransactionDto } from '@donately/app-frontend-library';

export const transactionAmount = (transaction: TransactionDto): number => {
  return transaction.transactionPurposes.reduce((
    prev,
    purpose
  ) => prev + purpose.amount, 0);
};

export const transactionCurrency = (transaction: TransactionDto): string => {
  return transaction?.foreignCurrency?.name ?? transaction?.bankAccount?.currency?.name;
};
