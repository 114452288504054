import { Pipe, PipeTransform } from '@angular/core';
import { ContactDto, GodchildDto } from '@donately/app-frontend-library';

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  transform(person: ContactDto | GodchildDto): string {
    if (!person) {
      return '';
    }

    return `${person.firstname} ${person.lastname}`;
  }

}
