<fuse-card
  (click)="navigate()"
  [ngStyle]="{ 'cursor': isEditable ? 'default' : 'pointer' }"
  class="flex flex-col pt-4 pb-7 px-8 group">
  <div [ngClass]="{ 'mt-10': !isEditable }" class="flex">
    <button
      (click)="edit()"
      *ngIf="isEditable"
      class="ml-auto invisible group-hover:visible"
      mat-icon-button>
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div [ngClass]="{ 'h-32': displayMode === 'Godchild'}" class="flex justify-center items-center -mt-2">
    <app-godchild-avatar
      *ngIf="displayMode === 'Godchild'"
      [godchild]="sponsorship.godchild"
      class="text-10xl"></app-godchild-avatar>
    <ng-container *ngIf="displayMode === 'Contact'">
      <div
        *ngFor="let contact of sponsorship.contacts; let i = index"
        [ngStyle]="{ 'z-index': sponsorship.contacts.length - i }"
        class="flex items-center justify-center w-10 h-10 ring-2 ring-bg-card -ml-4 rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200 transition-transform transform hover:-translate-y-2 hover:z-99999">
        {{contact.firstname?.charAt(0)}}
      </div>
    </ng-container>
  </div>

  <div class="text-center text-lg font-bold pb-4">{{displayName}}</div>

  <div class="flex flex-row justify-between border-t border-b py-4 mt-4">
    <div>
      <div class="font-bold">{{sponsorship.startDate | date: 'dd.MM.YYYY'}}</div>
      <div class="text-gray-500 text-sm">Start</div>
    </div>
    <div>
      <div class="text-right font-bold">{{(sponsorship.endDate | date: 'dd.MM.YYYY') || '-'}}</div>
      <div class="text-right text-gray-500 text-sm">Ende</div>
    </div>
  </div>

  <table class="mt-4">
    <tr
      *ngIf="displayMode === 'Godchild'">
      <td class="text-gray-500">Geburtsdatum</td>
      <td class="text-black">{{(sponsorship.godchild.birthdate | date: 'dd.MM.YYYY') || '-'}}</td>
    </tr>
    <tr
      *ngIf="displayMode === 'Godchild'">
      <td class="pb-4 text-gray-500">Geschlecht</td>
      <td class="pb-4 text-black">{{sponsorship.godchild.gender | gender | async}}</td>
    </tr>
    <tr>
      <td class="text-gray-500">Betrag</td>
      <td class="text-black">{{sponsorship.targetAmount}} {{sponsorship.currency.name}}</td>
    </tr>
    <tr>
      <td class="text-gray-500">Spendenzyklus</td>
      <td class="text-black">{{sponsorship.paymentCycle | paymentCycle | async}}</td>
    </tr>
    <tr>
      <td class="text-gray-500">Status</td>
      <td class="text-black">
        <app-sponsorship-status [sponsorship]="sponsorship"></app-sponsorship-status>
      </td>
    </tr>
  </table>
</fuse-card>
