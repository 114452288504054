import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CustomFieldDefinitionDto, CustomFieldDonatelyService, Entity } from '@donately/app-frontend-library';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AddOrUpdateValuesComponent } from './add-or-update-values/add-or-update-values.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlertService } from '../../../core/alert/alert.service';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html'
})
export class CustomFieldsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() identifier: string;
  @Input() group: string;
  @Input() entity: Entity;

  customFields: CustomFieldDefinitionDto[];

  private _alive = true;

  constructor(
    private _customFieldService: CustomFieldDonatelyService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {

  }

  ngOnInit(): void {
    this.loadFields();
  }

  ngOnDestroy(): void {
    this._alive = false;
  }

  ngOnChanges(): void {
    this.loadFields();
  }

  edit(): void {
    this.dialog.open(
      AddOrUpdateValuesComponent,
      {
        data: {
          customFields: this.customFields,
          group: this.group,
          identifier: this.identifier
        },
        width: '500px'
      }
    )
      .afterClosed().subscribe(() => this.loadFields());
  }

  checkboxChange(event: MatCheckboxChange, field: CustomFieldDefinitionDto): void {
    this._customFieldService.addOrUpdateCustomFieldData([
      {
        customFieldDataId: field.customFieldData?.[0]?.id,
        value: event.checked ? 'checked' : 'unchecked',
        customFieldDefinitionId: field.customFieldData?.[0] ? null : field.id,
        entityIdentifier: field.customFieldData?.[0] ? null : this.identifier
      }
    ]).subscribe(() => {
      this.alertService.push({
        level: 'success',
        message: 'Der Wert der Checkbox wurde erfolgreich gespeichert!',
        title: 'Erfolgreich gespeichert'
      });
      this.loadFields();
    });
  }

  private loadFields(): void {
    if (!this.identifier) {
      return;
    }

    this._customFieldService.getFields(this.group, this.identifier)
      .pipe(take(1))
      .subscribe(fields => this.customFields = fields);
  }

}
