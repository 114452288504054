import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { BankAccountDto } from '@donately/app-frontend-library';
import { Store } from '@ngrx/store';
import { delay, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { selectBankAccountsOfCurrentOrganisation } from '../../../../core/store/reducers/core.reducer';

@Component({
  selector: 'app-bank-account-autocomplete',
  templateUrl: './bank-account-autocomplete.component.html'
})
export class BankAccountAutocompleteComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() labelName: string;
  @Input() preselectFirstOption = false;

  bankAccounts: Observable<BankAccountDto[]>;

  private isFirstLoad = true;

  constructor(private _store: Store) {
  }

  ngOnInit(): void {
    this.bankAccounts = this.control
      .valueChanges
      .pipe(
        filter(value => typeof value === 'string' || value instanceof String),
        startWith(''),
        delay(0),
        withLatestFrom(this._store.select(selectBankAccountsOfCurrentOrganisation)),
        map(([value, bankAccounts]) => {
          if (this.isFirstLoad) {
            this.isFirstLoad = false;
            if (this.preselectFirstOption) {
              this.control.setValue(bankAccounts[0]);
            }
          }
          return bankAccounts.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
        })
      );
  }

  displayBankAccount(bankAccount: BankAccountDto): string {
    return bankAccount?.name;
  }

}
