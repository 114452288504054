<fuse-card class="mt-6 p-4 px-8 block group overflow-visible">
  <div class="flex flex-row w-full">
    <app-user-account-icon [user]="note.user"></app-user-account-icon>
    <div class="flex flex-col justify-center ml-4">
      <div class="font-bold">{{note.user?.firstname}} {{note.user?.lastname}}</div>
      <div class="text-gray-400">{{note.createDate | relativeDate}}</div>
    </div>
    <div
      [ngStyle]="{ 'visibility': noteActionsButton.menuOpen ? 'visible' : 'hidden' }"
      class="ml-auto group-hover:visible">
      <button #noteActionsButton="matMenuTrigger" [matMenuTriggerFor]="noteActions" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #noteActions>
        <button (click)="edit()" mat-menu-item>Bearbeiten</button>
        <button (click)="deleteNote(note.id)" mat-menu-item>Löschen</button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="!editMode" [innerHTML]="sanitizedHtml" class="block mt-8 min-w-0 break-all"></div>
  <div *ngIf="editMode" class="my-4">
    <quill-editor (onEditorCreated)="initEditMode()" [(ngModel)]="content" [modules]="modules"></quill-editor>
  </div>

  <div class="flex justify-end">
    <button *ngIf="!editMode" mat-flat-button><span class="text-sm">Kommentieren</span></button>
    <button (click)="update()" *ngIf="editMode" mat-raised-button><span class="text-sm">Speichern</span></button>
  </div>
</fuse-card>
