import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { CampaignDonatelyService, CampaignDto } from '@donately/app-frontend-library';
import { filter, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-autocomplete',
  templateUrl: './campaign-autocomplete.component.html'
})
export class CampaignAutocompleteComponent implements OnChanges {

  @Input() control: AbstractControl;
  @Input() labelName: string;

  campaigns: Observable<CampaignDto[]>;

  constructor(
    private _campaignService: CampaignDonatelyService
  ) {
  }

  ngOnChanges(): void {
    this.campaigns = this.control.valueChanges.pipe(
      filter(value => (typeof value === 'string' || value instanceof String)),
      startWith(''),
      switchMap(value => this._campaignService.getCampaigns({ filterValue: value }))
    );
  }

  display(campaign: CampaignDto): string {
    return campaign?.name;
  }

}
