import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class DateInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({ body: convertDateStringsToDates(request.body, false) });
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: convertDateStringsToDates(event.body) });
        }

        return event;
      })
    );
  }
}

const dateTimeRegex = /^\d{4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}.*Z*$/;

const convertDateStringsToDates = (input: unknown, toLocale = true): unknown => {
  // Ignore things that aren't objects.
  if (typeof input !== 'object') {
    return input;
  }

  for (const key in input) {
    if (!input.hasOwnProperty(key)) {
      continue;
    }

    const value = input[key];
    // Check for string properties which look like dates.
    if (typeof value === 'string' && value.match(dateTimeRegex)) {
      if (toLocale) {
        input[key] = moment.utc(value).local().format();
      } else {
        input[key] = moment(value).toISOString();
      }
    } else if (typeof value === 'object') {
      // Recurse into object
      convertDateStringsToDates(value);
    }
  }
  return input;
};
