import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _auth: AngularFireAuth) {
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this._auth.idToken.pipe(take(1), switchMap((token: string) => {
      if (token) {
        req = req.clone({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          setHeaders: { Authorization: `Bearer ${ token }` }
        });
      }
      return next.handle(req);
    }));


    // // Clone the request object
    // let newReq = req.clone();
    //
    // // Request
    // //
    // // If the access token didn't expire, add the Authorization header.
    // // We won't add the Authorization header if the access token expired.
    // // This will force the server to return a "401 Unauthorized" response
    // // for the protected API routes which our response interceptor will
    // // catch and delete the access token from the local storage while logging
    // // the user out from the app.
    // if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
    // {
    //     newReq = req.clone({
    //         headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
    //     });
    // }
    //
    // // Response
    // return next.handle(newReq).pipe(
    //     catchError((error) => {
    //
    //         // Catch "401 Unauthorized" responses
    //         if ( error instanceof HttpErrorResponse && error.status === 401 )
    //         {
    //             // Sign out
    //             this._authService.signOut();
    //
    //             // Reload the app
    //             location.reload();
    //         }
    //
    //         return throwError(error);
    //     })
    // );
  }
}
