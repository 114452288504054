import { Component, Input, OnInit } from '@angular/core';
import { CurrencyDto } from '@donately/app-frontend-library';
import { Observable } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { selectCurrenciesOfCurrentOrganisation } from '../../../../core/store/reducers/core.reducer';
import { Store } from '@ngrx/store';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-currency-autocomplete',
  templateUrl: './currency-autocomplete.component.html'
})
export class CurrencyAutocompleteComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() labelName: string;
  @Input() preselectFirstOption = false;

  currencies: Observable<CurrencyDto[]>;

  private isFirstLoad = true;

  constructor(private _store: Store) {
  }

  ngOnInit(): void {
    this.currencies = this.control
      .valueChanges
      .pipe(
        filter(value => typeof value === 'string' || value instanceof String),
        startWith(''),
        withLatestFrom(this._store.select(selectCurrenciesOfCurrentOrganisation)),
        map(([value, currencies]) => {
          if (this.isFirstLoad) {
            this.isFirstLoad = false;
            if (this.preselectFirstOption) {
              this.control.setValue(currencies[0]);
            }
          }
          return currencies.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
        })
      );
  }

  displayCurrency(currency: CurrencyDto): string {
    return currency?.name;
  }

}
