import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromCore from './reducers/core.reducer';
import { logoutReducer } from './reducers/core.reducer';


export interface DonatelyState {
  [fromCore.coreFeatureKey]: fromCore.CoreState;
}

export const reducers: ActionReducerMap<DonatelyState> = {
  [fromCore.coreFeatureKey]: fromCore.reducer
};


export const metaReducers: MetaReducer<DonatelyState>[] = !environment.production ? [logoutReducer] : [logoutReducer];
