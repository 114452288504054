import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { OpenLayoutDrawer } from '../../../core/store/actions/utils.actions';
import { Logout, UpdateUserStatus } from '../../../core/store/actions/auth.actions';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnDestroy {
  @Input() showAvatar: boolean = true;

  private _unsubscribeAll: Subject<unknown> = new Subject();

  /**
   * Constructor
   */
  constructor(
    public auth: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _store: Store
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    this._store.dispatch(new UpdateUserStatus(status));
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._store.dispatch(new Logout());
    this._router.navigate(['/sign-out']);
  }

  toggleLayoutDrawer(): void {
    this._store.dispatch(new OpenLayoutDrawer());
  }
}
