<app-dialog
  *transloco="let t; read: 'customFields'"
  [title]="isNewCustomField ? t('addCustomField') : t('editCustomField')"
  icon="dynamic_feed">
  <ng-container content>

    <form [formGroup]="form">
      <mat-form-field *ngIf="isNewCustomField" class="w-full">
        <mat-label>{{ t('type') }}</mat-label>
        <mat-select formControlName="type">
          <ng-container *ngFor="let fieldType of customFieldType | keyvalue">
            <mat-option
              *ngIf="enumTranslator.translate('customFieldType.' + fieldType.value)?.length > 0"
              [value]="fieldType.value">
              {{enumTranslator.translate('customFieldType.' + fieldType.value)}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>{{t('fieldName')}}</mat-label>
        <input formControlName="label" matInput type="text">
      </mat-form-field>
    </form>

  </ng-container>
  <ng-container footer>

    <!-- Cancel -->
    <button mat-stroked-button matDialogClose>{{t('cancel')}}</button>

    <!-- Save button -->
    <button
      (click)="save()"
      [color]="'primary'"
      [disabled]="form.disabled"
      class="relative"
      mat-flat-button>
                    <span [ngClass]="{ 'invisible': form.disabled }">
                        {{isNewCustomField ? t('saveNew') : t('save')}}
                    </span>
      <mat-progress-spinner
        *ngIf="form.disabled"
        [diameter]="18"
        [mode]="'indeterminate'"
        class="absolute m-auto"></mat-progress-spinner>
    </button>
  </ng-container>
</app-dialog>
