/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { HasOrganisationsGuard } from './core/auth/guards/has-organisations.guard';
import { InitialDataResolver } from './app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/home'
  { path: '', pathMatch: 'full', redirectTo: 'home' },

  // Redirect signed in user to the '/home'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            m => m.AuthConfirmationRequiredModule
          )
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(
            m => m.AuthForgotPasswordModule
          )
      },
      {
        path: 'reset-password/:token',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            m => m.AuthResetPasswordModule
          )
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
      }
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            m => m.AuthUnlockSessionModule
          )
      }
    ]
  },

  // Landing routes
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'onboarding',
        loadChildren: () => import('app/modules/landing/onboarding.module').then(m => m.OnboardingModule)
      }
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard, HasOrganisationsGuard],
    canActivateChild: [AuthGuard, HasOrganisationsGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/admin/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('app/modules/admin/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'godchildren',
        loadChildren: () => import('app/modules/admin/godchildren/godchildren.module').then(m => m.GodchildrenModule)
      },
      {
        path: 'campaigns',
        loadChildren: () => import('app/modules/admin/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'sponsorships',
        loadChildren: () => import('app/modules/admin/sponsorships/sponsorships.module').then(m => m.SponsorshipsModule)
      },
      {
        path: 'transactions',
        loadChildren: () => import('app/modules/admin/transactions/transactions.module').then(m => m.TransactionsModule)
      },
      {
        path: 'smart-grid',
        loadChildren: () => import('app/modules/admin/smart-grid/smart-grid.module').then(m => m.SmartGridModule)
      },
      {
        path: 'accounting',
        loadChildren: () => import('app/modules/admin/accounting/accounting.module').then(m => m.AccountingModule)
      }
    ]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];
