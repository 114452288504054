<div *transloco="let t; read: 'customFields'" class="group">
  <div class="flex flex-row justify-between">
    <div class="text-2xl font-semibold">Benutzerdefinierte Felder</div>
    <div>
      <button (click)="edit()" class="flex ml-auto invisible group-hover:visible" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>

  <div class="p-4">
    <ng-container *ngFor="let field of customFields">

      <div *ngIf="field.type === 'Input'" class="w-full mb-4">
        <dt class="font-bold">{{field.label}}</dt>
        <dd>{{field.customFieldData && field.customFieldData[0]?.value ?? '-'}}</dd>
      </div>

      <div *ngIf="field.type === 'Textarea'" class="w-full mb-4">
        <dt class="font-bold">{{field.label}}</dt>
        <div class="whitespace-pre-wrap">{{field.customFieldData && field.customFieldData[0]?.value ?? '-'}}</div>
      </div>

      <div *ngIf="field.type === 'Checkbox'" class="w-full mb-4">
        <mat-checkbox
          (change)="checkboxChange($event, field)"
          [checked]="field.customFieldData && field.customFieldData[0]?.value === 'checked'">{{field.label}}</mat-checkbox>
      </div>

    </ng-container>
  </div>
</div>
