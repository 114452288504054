import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  ContactDto,
  GodchildDonatelyService,
  GodchildDto,
  SponsorshipDonatelyService
} from '@donately/app-frontend-library';
import { filter, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-godchild-autocomplete',
  templateUrl: './godchild-autocomplete.component.html'
})
export class GodchildAutocompleteComponent implements OnChanges {

  @Input() control: AbstractControl;
  @Input() labelName: string;
  @Input() contact: ContactDto;

  @ViewChild('inputElement') inputElement: ElementRef;

  godchildren: Observable<GodchildDto[]>;

  constructor(
    private _godchildService: GodchildDonatelyService,
    private _sponsorshipService: SponsorshipDonatelyService
  ) {
  }

  ngOnChanges(): void {
    this.godchildren = this.control.valueChanges.pipe(
      filter(value => typeof value === 'string' || value instanceof String),
      startWith(''),
      switchMap(value => {
        return this._godchildService.getGodchildren({
          filterValue: value,
          contactId: this.contact?.id || undefined,
          offset: 0
        });
      })
    );
  }

  displayGodchild(godchild: GodchildDto): string {
    if (godchild?.firstname == null) {
      return;
    }

    return `${godchild?.firstname} ${godchild?.lastname}`;
  }

  focus(): void {
    this.inputElement.nativeElement.focus();
  }

}
