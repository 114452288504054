import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Gender } from '@donately/app-frontend-library';

@Component({
  selector: 'app-gender-autocomplete',
  templateUrl: './gender-autocomplete.component.html'
})
export class GenderAutocompleteComponent {

  @Input() labelName: string;
  @Input() control: FormControl;

  gender = Gender;
}
