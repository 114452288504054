import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CampaignDonatelyService, CampaignDto, DonationPurposeDto } from '@donately/app-frontend-library';

@Component({
  selector: 'app-donation-purpose-autocomplete',
  templateUrl: './donation-purpose-autocomplete.component.html'
})
export class DonationPurposeAutocompleteComponent {

  @Input() control: AbstractControl;
  @Input() campaign: CampaignDto;
  @Input() labelName: string;

  constructor(
    private _campaignService: CampaignDonatelyService
  ) {
  }

  displayPurpose(purpose: DonationPurposeDto): string {
    return purpose?.name;
  }
}
