<mat-form-field class="w-full">
  <mat-label>{{labelName}}</mat-label>
  <input
    #inputElement
    [formControl]="control"
    [matAutocomplete]="contactAutocomplete"
    matInput
    type="text">
  <mat-autocomplete #contactAutocomplete="matAutocomplete" [displayWith]="displayGodchild" autoActiveFirstOption>
    <mat-option
      *ngFor="let contact of godchildren | async"
      [value]="contact">{{ displayGodchild(contact) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
