import { Component, DoCheck, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html'
})
export class MapsComponent implements OnDestroy, DoCheck {
  @Input() placeFilters: string[] = [];

  currentQuery: string;
  isLoaded = false;
  url: SafeResourceUrl;
  private language = '';

  private _alive = true;

  constructor(private _sanitizer: DomSanitizer, private _translocoService: TranslocoService) {
    this._translocoService.langChanges$.pipe(takeWhile(() => this._alive)).subscribe((lang) => {
      if (lang === 'en-US') {
        this.language = 'en';
      } else if (lang === 'de-CH') {
        this.language = 'de';
      }
    });
  }

  ngOnDestroy(): void {
    this._alive = false;
  }

  ngDoCheck(): void {
    const filters = this.placeFilters.filter(filter => filter != null && filter.length > 0);
    let newQuery = '';

    if (filters.length > 0) {
      for (const filter of filters) {
        newQuery += ` ${ filter }`;
      }

      newQuery = newQuery.trimLeft().trimRight().replace(/\s+/g, '+');
    }

    if (this.currentQuery === newQuery) {
      return;
    }

    this.currentQuery = newQuery;

    this.url = this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.google.com/maps/embed/v1/place?key=${ environment.google.mapsApiKey }&q=${ newQuery }&language=${ this.language }`
    );
  }
}
