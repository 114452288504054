import { Action } from '@ngrx/store';
import { OrganisationDto } from '@donately/app-frontend-library';

export enum AuthActionTypes {
  SET_ORGANISATIONS = '[CORE] Update Organisations',
  SET_ORGANISATIONS_SUCCESS = '[CORE] Update Organisations Success',

  UPDATE_USER_STATUS = '[AUTH] Update user status',

  LOGOUT = '[AUTH] Logout'
}

export class SetOrganisations implements Action {
  readonly type = AuthActionTypes.SET_ORGANISATIONS;
}

export class SetOrganisationsSuccess implements Action {
  readonly type = AuthActionTypes.SET_ORGANISATIONS_SUCCESS;

  constructor(public organisations: OrganisationDto[]) {
  }
}

export class UpdateUserStatus implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_STATUS;

  constructor(public status: string) {
  }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export type AuthActions = SetOrganisations | SetOrganisationsSuccess;
