import { Action, ActionReducer, createSelector } from '@ngrx/store';
import { UtilsActionTypes } from '../actions/utils.actions';
import { DonatelyState } from '../index';
import { OrganisationDto } from '@donately/app-frontend-library';
import { AuthActionTypes, SetOrganisationsSuccess } from '../actions/auth.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ActivateCurrencySuccess,
  DisableCurrencySuccess,
  OrganisationActionTypes,
  UpdateOrganisationSuccess
} from '../actions/organisation.actions';

// Feature Key
export const coreFeatureKey = 'core';

// Entity Stage & Adapter
export interface CoreState extends EntityState<OrganisationDto> {
  isLayoutDrawerOpen: boolean;
  currentOrganisationId: string;
}

export const adapter: EntityAdapter<OrganisationDto> = createEntityAdapter<OrganisationDto>();

// Initial State
export const initialState: CoreState = adapter.getInitialState({
  isLayoutDrawerOpen: false,
  currentOrganisationId: null
});

// Reducer
export const reducer = (state = initialState, action: Action): CoreState => {
  switch (action.type) {
    case UtilsActionTypes.OPEN_LAYOUT_DRAWER:
      return { ...state, isLayoutDrawerOpen: true };
    case UtilsActionTypes.CLOSE_LAYOUT_DRAWER:
      return { ...state, isLayoutDrawerOpen: false };
    case AuthActionTypes.SET_ORGANISATIONS_SUCCESS:
      const updatedState = adapter.setAll((action as SetOrganisationsSuccess).organisations, state);
      return { ...updatedState, currentOrganisationId: updatedState.entities[updatedState.ids[0]]?.id };
    case OrganisationActionTypes.ACTIVATE_CURRENCY_SUCCESS:
      return adapter.updateOne((action as ActivateCurrencySuccess).organisation, state);
    case OrganisationActionTypes.DISABLE_CURRENCY_SUCCESS:
      return adapter.updateOne((action as DisableCurrencySuccess).organisation, state);
    case OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS:
      return adapter.updateOne((action as UpdateOrganisationSuccess).organisation, state);
    default:
      return state;
  }
};

// Selectors
export const selectFeature = (state: DonatelyState): CoreState => state[coreFeatureKey];

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const selectLayoutDrawerState = createSelector(
  selectFeature,
  (state: CoreState) => state.isLayoutDrawerOpen
);

export const selectOrganisationIds = createSelector(
  selectFeature,
  selectIds
);
export const selectOrganisationEntities = createSelector(
  selectFeature,
  selectEntities
);
export const selectAllOrganisations = createSelector(
  selectFeature,
  selectAll
);
export const selectOrganisationTotal = createSelector(
  selectFeature,
  selectTotal
);
export const selectCurrentOrganisationId = createSelector(
  selectFeature,
  (state: CoreState) => state.currentOrganisationId
);

export const selectCurrentOrganisation = createSelector(
  selectOrganisationEntities,
  selectCurrentOrganisationId,
  (organisationEntities, orgId) => organisationEntities[orgId]
);

export const selectCurrentBaseCurrency = createSelector(
  selectCurrentOrganisation,
  (org: OrganisationDto) => org.currencies.find(c => c.isBaseCurrency)
);

export const selectCurrenciesOfCurrentOrganisation = createSelector(
  selectCurrentOrganisation,
  (org: OrganisationDto) => org.currencies
);

export const selectBankAccountsOfCurrentOrganisation = createSelector(
  selectCurrentOrganisation,
  (org: OrganisationDto) => org.bankAccounts
);

export const logoutReducer = (specificReducer: ActionReducer<any>): ActionReducer<any> => (state, action): unknown => {
  if (action.type === AuthActionTypes.LOGOUT) {
    state = undefined;
  }

  return specificReducer(state, action);
};
