<div
  cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  class="relative flex flex-col -m-6">


  <!-- Header -->
  <div cdkDragHandle class="flex flex-col sm:flex-row items-center px-6 py-4 bg-primary">

    <!-- Icon -->
    <div *ngIf="icon" class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 dark:text-primary-50">
      <mat-icon class="text-on-primary">{{icon}}</mat-icon>
    </div>

    <!-- Title -->
    <div class="text-xl leading-6 font-medium flex-grow text-on-primary">{{title}}</div>

    <!-- Dismiss button -->
    <button
      class="ml-20"
      mat-icon-button
      matDialogClose>
      <mat-icon
        [svgIcon]="'heroicons_outline:x'"
        class="text-on-primary"></mat-icon>
    </button>

  </div>


  <!-- Message -->
  <div class="text-secondary p-6 px-8">
    <ng-content select="[content]"></ng-content>
  </div>

  <!-- Actions -->
  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3">
    <ng-content select="[footer]"></ng-content>
  </div>

</div>
