<fuse-card class="flex flex-col">

  <h2 class="pl-8 pt-8 text-2xl">Transaktionen</h2>
  <h2 *ngIf="transactions?.length > 0" class="pl-8 pb-8 text-sm text-gray-400">{{transactions.length}}
    Transaktionen</h2>

  <table *ngIf="transactions?.length > 0" class="text-left max-w-full w-full">
    <tr>
      <th class="w-8 min-w-8"></th>
      <th *ngIf="columns.includes('Date')" class="pb-2">Datum</th>
      <th *ngIf="columns.includes('Name')" class="pb-2">Name</th>
      <th *ngIf="columns.includes('Amount')" class="pb-2">Betrag</th>
      <th *ngIf="columns.includes('Campaign')" class="pb-2">Kampagne</th>
      <th *ngIf="columns.includes('DonationPurpose') || columns.includes('CondensedPurpose')" class="pb-2">
        Spendenzweck
      </th>
      <th *ngIf="columns.includes('Godchild')" class="pb-2">Patenkind</th>
      <th *ngIf="columns.includes('Status')" class="pb-2">Status</th>
      <th class="w-8 min-w-8"></th>
    </tr>
    <tr
      (click)="openTransaction(row.id)"
      *ngFor="let row of transactions"
      class="border-t border-b hover:bg-hover cursor-pointer">
      <td class="w-8 min-w-8"></td>
      <td *ngIf="columns.includes('Date')" class="py-4 px-2">{{$any(row.date) | date: 'dd.MM.YYYY'}}</td>
      <td
        *ngIf="columns.includes('Name')"
        class="py-4 px-2">{{row.contact.firstname}} {{row.contact.lastname}}</td>
      <td
        *ngIf="columns.includes('Amount')"
        class="py-4 px-2 whitespace-nowrap">{{row.bankAccount.currency.name}} {{row | transactionSum}}</td>
      <td *ngIf="columns.includes('CondensedPurpose')">
        <span>{{row.transactionPurposes[0].campaign.name}} ({{row.transactionPurposes[0].donationPurpose.name}})</span>
        <span>{{row.transactionPurposes[0].godchild.firstname}} {{row.transactionPurposes[0].godchild.lastname}}</span>
        <span *ngIf="row.transactionPurposes.length > 1">+{{row.transactionPurposes.length - 1}}</span>
      </td>
      <td *ngIf="columns.includes('Campaign')" class="py-4">{{row.transactionPurposes[0].campaign.name}}</td>
      <td
        *ngIf="columns.includes('DonationPurpose')"
        class="py-4 px-2">{{row.transactionPurposes[0].donationPurpose.name}}</td>
      <td
        *ngIf="columns.includes('Godchild')"
        class="py-4 px-2">{{row.transactionPurposes[0].godchild.firstname}} {{row.transactionPurposes[0].godchild.lastname}}</td>
      <td *ngIf="columns.includes('Status')" class="py-4">
        <app-transaction-import-status [isImportApproved]="row.isImportApproved"></app-transaction-import-status>
      </td>
      <td class="w-8 min-w-8"></td>
    </tr>
  </table>

  <div
    *ngIf="transactions?.length === 0"
    class="flex justify-center items-center py-4">
    Keine Transaktionen
  </div>

</fuse-card>
