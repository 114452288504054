<fuse-alert
  (dismissedChanged)="alertService.clear(i)"
  *ngFor="let alert of alertService.alerts?.reverse(); let i = index"
  [@fadeOut]
  [appearance]="'border'"
  [dismissible]="true"
  [type]="alert.level"
  class="mt-2">
  <span fuseAlertTitle>{{ alert?.title }}</span>
  {{ alert?.message }}
</fuse-alert>
